import React from "react";
import {useSelector} from "react-redux";
import LinkedCases from "../../../components/LinkedCases";
import ProductDetails from "../../../components/ProductDetails";
import ProductHero from "../../../components/ProductHero";
import ProductQuickNav from "../../../components/ProductQuickNav";
import Footer from "../../../components/Footer";
import Helmet from "react-helmet";
import {shuffleCasesWithProduct} from "../../../redux/actions/actions";

const EduLeervisuals = (props) => {
    const products = useSelector((state) => state.products.edu);
    const productId = 7;

    return (
        <div id={`edu-${products[productId].titleInCode}`}>
            <Helmet>
                <title>
                    {products[productId].title
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace("&shy;", "")
                        .replace("&#8209;", "-")}
                </title>
                <meta
                    name="description"
                    content={products[productId].lead
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace("&shy;", "")}
                />
                <meta name="keywords" content={products[productId].keywords} />
            </Helmet>{" "}
            <ProductHero activeProduct={products[productId]}></ProductHero>
            <ProductDetails
                activeProduct={products[productId]}
            ></ProductDetails>
            {/* <LinkedCases
                title="Een greep uit <br/>onze <span>realisaties</span>"
                caseTitles={
                    shuffleCasesWithProduct(productId).payload.linkedCaseTitles
                }
            ></LinkedCases> */}
            <ProductQuickNav
                activeProduct={products[productId]}
            ></ProductQuickNav>
            <Footer cta={products[productId].footerCta}></Footer>
        </div>
    );
};

export default EduLeervisuals;
