import React, { useEffect, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import { serviceHeroImgs } from "../assets/imgs/services/ServiceImgImports";
StudioLife.propTypes = {};

function StudioLife(props) {
  const slide = document.getElementsByClassName("slider-carousel");
  // slide.scrollIntoView(0, 0);
  const [activeSlide, setActiveSlide] = useState(0);
  const imgWidth = 536;
  const carousel = {
    strengths: [
      {
        id: 0,
        img: serviceHeroImgs[0],
        isActive: true,
      },
      {
        id: 1,
        img: serviceHeroImgs[1],
        isActive: false,
      },
      {
        id: 2,
        img: serviceHeroImgs[2],
        isActive: false,
      },
      {
        id: 3,
        img: serviceHeroImgs[3],
        isActive: false,
      },
      {
        id: 4,
        img: serviceHeroImgs[4],
        isActive: false,
      },
    ],
  };

  const scrollTo = (strength) => {
    if (strength < carousel.strengths.length - 2) {
      document.querySelector(".strengths__slider").scrollLeft =
        strength * imgWidth;
    } else {
      document.querySelector(".strengths__slider").scrollLeft =
        strength * 530 - (100 % -imgWidth);
    }

    // .scrollIntoView({
    //   behavior: "smooth",
    //   block: "nearest",
    //   inline: "start",
    // });
  };
  
  const handleChange = (slide) => {
    setActiveSlide(slide);
    scrollTo(slide);
  };
  
  const GoBack = () => {
    if (activeSlide > 0) {
        setActiveSlide(activeSlide - 1);
        scrollTo(activeSlide - 1);
    } else if (activeSlide === 4) {
        setActiveSlide(carousel.strengths.length - 2);
        scrollTo(carousel.strengths.length - 2);
    } else {
        // setActiveSlide(activeSlide);
    }
  };

  const GoFurther = () => {
    if (activeSlide < carousel.strengths.length - 1) {
        setActiveSlide(activeSlide + 1);
        scrollTo(activeSlide + 1);
    } else if (activeSlide === 0) {
        scrollTo(carousel.strengths.length + 2);
        setActiveSlide(carousel.strengths.length + 2);
    }
  };

  return (
    <div className="strengths">
      <h2 className="strengths__title">Studio life</h2>
      <div className="strengths__slider">
        {carousel.strengths.map((slide, i) => (
          <img
            key={i}
            src={slide.img}
            onClick={() => handleChange(i)}
            onMouseEnter={() => setActiveSlide(i)}
            id={`slider-carousel__item${i}`}
            className={`slider-carousel__item ${
              i === activeSlide ? "slider-carousel__item--active" : ""
            }`}
          ></img>
        ))}
        <div className="slider-carousel__mock-item"></div>
      </div>
      <div className="strengths__slider-nav">
        <div className="strengths__slider-nav-btns">
          <div
            className={`btn-green-on-green ${
              activeSlide === 0 ? "btn-green-on-green--inactive" : ""
            }`}
            onClick={() => GoBack()}
          >
            <i className="icon-arrow-left"></i>
          </div>
          <div
            className={`btn-green-on-green btn-green--right ${
              activeSlide === carousel.strengths.length - 1
                ? "btn-green-on-green--inactive"
                : ""
            }`}
            onClick={() => GoFurther()}
          >
            <i className="icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudioLife;
