import { connect } from "react-redux";
import React from "react";
import Footer from "../../../components/Footer";
import Motto from "../../../components/Motto";
import ServiceQuickNav from "../../../components/ServiceQuickNav";
import ServiceProces from "../../../components/ServiceProces";
import ServiceHero from "../../../components/ServiceHero";
import LinkedCases from "../../../components/LinkedCases";
import Helmet from "react-helmet";
import { shuffleCasesWithService } from "../../../redux/actions/actions";

function EduContentCreatie(props) {
  const { services } = props;
  return (
    <div>
      <Helmet>
        <title>
          {services[3].title
            .replace(/(<([^>]+)>)/gi, "")
            .replace("&shy;", "")
            .replace("&#8209;", "-")}
        </title>
        <meta
          name="description"
          content={services[3].lead
            .replace(/(<([^>]+)>)/gi, "")
            .replace("&shy;", "")}
        ></meta>
        <meta name="keywords" content={services[3].keywords}></meta>
      </Helmet>
      <ServiceHero activeService={services[3]}></ServiceHero>
      <Motto source={services[3].motto}></Motto>
      <ServiceProces activeService={services[3]}></ServiceProces>
      <ServiceQuickNav activeService={services[3]}></ServiceQuickNav>
      <LinkedCases
        title="Een greep uit <br/>onze <span>realisaties</span>"
        caseTitles={shuffleCasesWithService(4).payload.linkedCaseTitles}
      ></LinkedCases>
      <Footer cta={services[3].footerCta}></Footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: state.services.edu,
  cases: state.cases,
});

export default connect(mapStateToProps, null)(EduContentCreatie);
