import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { changeService } from "../../redux/actions/actions";
import WorkplaceOnderzoek from "./services/WorkplaceOnderzoek";
import WorkplaceStrategie from "./services/WorkplaceStrategie";
import WorkplaceProductDesign from "./services/WorkplaceProductDesign";
import WorkplaceContentCreatie from "./services/WorkplaceContentCreatie";
import WorkplaceBegeleiding from "./services/WorkplaceBegeleiding";
import Header from "../../components/Header";
import Helmet from "react-helmet";

function WorkplaceService(props) {
  const dispatch = useDispatch();
  const currentUrl = useLocation().pathname;
  const serviceIndex = currentUrl.indexOf("diensten") + 9;
  useLocation();

  useEffect(() => {
    if (currentUrl.slice(serviceIndex, currentUrl.length).slice(-1) === "/") {
      dispatch(
        changeService(
          currentUrl.slice(serviceIndex, currentUrl.length).split("/")[0]
        )
      );
    } else {
      dispatch(
        changeService(currentUrl.slice(serviceIndex, currentUrl.length))
      );
    }
  }, []);

  const { services } = props;
  return (
    <div>
      <Header headerBG="beige"></Header>
      <Helmet titleTemplate="Oetang | Workplace - %s"></Helmet>
      <Routes>
        <Route
          exact
          path={`/${services[0].titleInCode}`}
          element={<WorkplaceOnderzoek />}
        />
        <Route
          exact
          path={`/${services[1].titleInCode}`}
          element={<WorkplaceStrategie />}
        />
        <Route
          exact
          path={`/${services[2].titleInCode}`}
          element={<WorkplaceProductDesign />}
        />
        <Route
          exact
          path={`/${services[3].titleInCode}`}
          element={<WorkplaceContentCreatie />}
        />
        <Route
          exact
          path={`/${services[4].titleInCode}`}
          element={<WorkplaceBegeleiding />}
        />
        <Route exact path="/" element={<WorkplaceOnderzoek />} />
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: state.services.job,
});

export default connect(mapStateToProps, null)(WorkplaceService);
