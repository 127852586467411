import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductQuickNav from "../../components/ProductQuickNav";
import ServiceOverview from "../../components/ServiceOverview";
import CtaBanner from "../../components/CtaBanner";
import Strengths from "../../components/Strengths";
import SectorHomeHero from "../../components/SectorHomeHero";
import Helmet from "react-helmet";

class WorkplaceHome extends Component {
  render() {
    return (
      <div id="workplacehome">
        <Header headerBG={"green"} />
        <Helmet>
          <title>Workplace learning</title>
          <meta
            name="description"
            content="Oetang is graag partner van Learning & Development diensten die mikken op lifelong learning en workplace learning bij hun medewerkers."
          ></meta>
          <meta
            name="keywords"
            content="learning, leerervaring, design, leren, lxd, leerproduct, workplace, business, learning &amp; development, lifelong learning, learning experience design, consultancy"
          ></meta>
        </Helmet>
        <SectorHomeHero></SectorHomeHero>
        <ServiceOverview></ServiceOverview>
        {/*<Motto source="In onze LXD Studio creëren en optimaliseren wij leerervaringen in vijf cruciale stappen: <span>onderzoek</span>, <span>strategie</span>, <span>product design</span>, <span>content creatie</span> en <span>begeleiding</span>."></Motto> */}
        <ProductQuickNav></ProductQuickNav>
        <CtaBanner
          title={`Jouw project is uniek? <span>We werken op maat!</span>`}
        ></CtaBanner>
        <Strengths></Strengths>
        <Footer
          page="workplace"
          cta="Dus, wanneer <span>beginnen we?</span>"
        ></Footer>
      </div>
    );
  }
}

export default WorkplaceHome;
