import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const LinkedCases = (props) => {
  const { title, caseTitles } = props;
  const linkedCase1 = props.cases.portfolio.filter(
    (item) => item.titleInCode === caseTitles[0]
  )[0];
  const linkedCase2 = props.cases.portfolio.filter(
    (item) => item.titleInCode === caseTitles[1]
  )[0];
  const linkedCase3 = props.cases.portfolio.filter(
    (item) => item.titleInCode === caseTitles[2]
  )[0];

  return (
    <section className="linkedCases">
      <h2
        className="linkedCases__title"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>
      <div className="linkedCases__wrapper">
        <div className="case-card">
          <ul className="case-card__header">
            {linkedCase1.sector.map((sector, i) => (
              <li key={i}>{sector === "edu" ? "education" : "workplace"}</li>
            ))}
          </ul>
          <div className="case-card__body">
            <h5 className="case-card__body-partner">{linkedCase1.partner}</h5>
            <h4 className="case-card__body-title">
              {linkedCase1.tileBaseline}
            </h4>
          </div>
          <div
            className="case-card__footer"
            // style={{ backgroundImage: `url(${linkedCase.tileImg})` }}
          >
            <img
              alt={`${linkedCase1.title} maakten we in opdracht van ${linkedCase1.partner}.`}
              src={linkedCase1.tileImg}
            ></img>
          </div>
        </div>
        <div className="case-card">
          <ul className="case-card__header">
            {linkedCase2.sector.map((sector, i) => (
              <li key={i}>{sector === "edu" ? "education" : "workplace"}</li>
            ))}
          </ul>
          <div className="case-card__body">
            <h5 className="case-card__body-partner">{linkedCase2.partner}</h5>
            <h4 className="case-card__body-title">
              {linkedCase2.tileBaseline}
            </h4>
          </div>
          <div
            className="case-card__footer"
            // style={{ backgroundImage: `url(${linkedCase.tileImg})` }}
          >
            <img
              alt={`${linkedCase2.title} maakten we in opdracht van ${linkedCase2.partner}.`}
              src={linkedCase2.tileImg}
            ></img>
          </div>
        </div>
        <div className="case-card">
          <ul className="case-card__header">
            {linkedCase3.sector.map((sector, i) => (
              <li key={i}>{sector === "edu" ? "education" : "workplace"}</li>
            ))}
          </ul>
          <div className="case-card__body">
            <h5 className="case-card__body-partner">{linkedCase3.partner}</h5>
            <h4 className="case-card__body-title">
              {linkedCase3.tileBaseline}
            </h4>
          </div>
          <div
            className="case-card__footer"
            // style={{ backgroundImage: `url(${linkedCase.tileImg})` }}
          >
            <img
              alt={`${linkedCase3.title} maakten we in opdracht van ${linkedCase3.partner}.`}
              src={linkedCase3.tileImg}
            ></img>
          </div>
        </div>
      </div>
      <Link to="" className="linkedCases__btn btn-green btn-green--right">
        Bekijk meer werk<i className="icon-arrow-right"></i>
      </Link>
    </section>
  );
};

LinkedCases.propTypes = {
  title: PropTypes.string,
  caseTitles: PropTypes.array,
};

const mapStateToProps = (state) => ({
  cases: state.cases,
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(LinkedCases);
