import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ServiceHero extends Component {
  render() {
    const { activeSector, activeService } = this.props;
    return (
      <section className={`service-hero service-hero--${activeSector}`}>
        <ul className="service-hero-breadcrumb">
          <li>
            <Link to={`/${activeSector === "edu" ? "edu" : "workplace"}`}>
              {activeSector === "edu" ? "Education" : "Workplace"}
            </Link>
          </li>
          <li dangerouslySetInnerHTML={{ __html: activeService.title }}></li>
        </ul>
        <div className="service-hero-pagelead">
          <div className="service-hero-pagelead__info">
            <h1
              className="service-hero-pagelead__info-title"
              dangerouslySetInnerHTML={{ __html: activeService.title }}
            ></h1>
            <p className="service-hero-pagelead__info-lead">
              {activeService.lead}
            </p>
          </div>
          <div className="service-hero-pagelead__aside">
            <img alt="" src={activeService.heroImg}></img>
            <div>
              <img
                alt="Geometrische vorm van de dienst"
                src={activeService.iconOnWhite}
              ></img>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(ServiceHero);
