import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { current } from "@reduxjs/toolkit";

const Cases = (props) => {
  const listCases = props.cases.portfolio;
  const productGroups = listCases.productgroup;
  
  let sortedCases1 = props.cases.portfolio.sort((a, b) =>
  b.date.split('/').reverse().join().localeCompare(a.date.split('/').reverse().join()));
  
  console.log(listCases);

  return (
    <section className="cases">
      <ul className="cases__wrapper">
        {listCases.map((currentCase, i) => (
          <li key={i}>
            <div className="case-card">
              <div className="case-card__body">
                <h5 className="case-card__body-partner">
                  {currentCase.partner}
                </h5>
                <h4 className="case-card__body-title">
                  {currentCase.tileBaseline}
                </h4>
                <ul className="case-card__body-labels">
                {currentCase.productgroup.map((productgroup, i) =>
                  currentCase.sector.includes("edu") ? (
                    <li
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: props.products.edu[productgroup].label,
                      }}
                    ></li>
                  ) : (
                    <li
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: props.products.job[productgroup].label,
                      }}
                    ></li>
                  )
                )}
              </ul>
              </div>
              <div className="case-card__footer">
                <img
                  alt={`${currentCase.title} maakten we in opdracht van ${currentCase.partner}.`}
                  src={currentCase.tileImg}
                ></img>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

const mapStateToProps = (state) => ({
  cases: state.cases,
  products: state.products,
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(Cases);
