import iconOnWhiteResearch from "./svg_transparant/s_Oetang__serv_research.svg";
import iconOnWhiteStrategy from "./svg_transparant/s_Oetang__serv_strategy.svg";
import iconOnWhiteProductDesign from "./svg_transparant/s_Oetang__serv_product-design.svg";
import iconOnWhiteContentCreation from "./svg_transparant/s_Oetang__serv_content-creation.svg";
import iconOnWhiteGuidance from "./svg_transparant/s_Oetang__serv_implementation.svg";
import iconResearch from "./svg_without-white/s_Oetang__serv_research.svg";
import iconStrategy from "./svg_without-white/s_Oetang__serv_strategy.svg";
import iconProductDesign from "./svg_without-white/s_Oetang__serv_product-design.svg";
import iconContentCreation from "./svg_without-white/s_Oetang__serv_content-creation.svg";
import iconGuidance from "./svg_without-white/s_Oetang__serv_implementation.svg";

export const serviceShapesOnWhite = {
  research: iconOnWhiteResearch,
  strategy: iconOnWhiteStrategy,
  productDesign: iconOnWhiteProductDesign,
  contentCreation: iconOnWhiteContentCreation,
  guidance: iconOnWhiteGuidance,
};

export const serviceShapesWithoutWhite = {
  research: iconResearch,
  strategy: iconStrategy,
  productDesign: iconProductDesign,
  contentCreation: iconContentCreation,
  guidance: iconGuidance,
};
