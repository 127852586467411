import { initialState } from "../reducers/reducers";

export const fetchPageInfo = () => {
  return function (dispatch) {
    dispatch({
      type: "FETCH_PAGE_INFO",
      payload: initialState,
    });
  };
};

export const changeSector = (newSector) => {
  return function (dispatch) {
    dispatch({
      type: "CHANGE_SECTOR",
      payload: { newSector },
    });
  };
};

export const closeMenu = () => {
  return function (dispatch) {
    dispatch({
      type: "CLOSE_MENU",
    });
  };
};

export const toggleMenu = () => {
    return function (dispatch) {
      dispatch({
        type: "TOGGLE_MENU",
      });
    };
  };

export const changeService = (newService) => {
  return {
    type: "CHANGE_SERVICE",
    payload: { newService },
  };
};

export const changeProduct = (newProduct) => {
  return {
    type: "CHANGE_PRODUCT",
    payload: { newProduct },
  };
};

export const shuffleCasesWithService = (idOfService) => {
  const checkServicegroup = (casus) => {
    return casus.servicegroup.includes(idOfService);
  };
  const casesWithService =
    initialState.cases.portfolio.filter(checkServicegroup);
  const shuffledCasesWithService = casesWithService.sort(
    () => 0.5 - Math.random()
  );
  const selectedCasesWithService = shuffledCasesWithService.slice(0, 3);
  const linkedCaseTitles = selectedCasesWithService.map((selectedCase) => {
    return selectedCase.titleInCode;
  });
  return {
    type: "FETCH_SERVICE_CASE_TITLES",
    payload: { linkedCaseTitles },
  };
};

export const shuffleCasesWithProduct = (idOfProduct) => {
  const checkProductgroup = (casus) => {
    return casus.productgroup.includes(idOfProduct);
  };
  const casesWithProduct =
    initialState.cases.portfolio.filter(checkProductgroup);
  const shuffledCasesWithProduct = casesWithProduct.sort(
    () => 0.5 - Math.random()
  );
  const selectedCasesWithProduct = shuffledCasesWithProduct.slice(0, 3);
  const linkedCaseTitles = selectedCasesWithProduct.map((selectedCase) => {
    return selectedCase.titleInCode;
  });
  return {
    type: "FETCH_PRODUCT_CASE_TITLES",
    payload: { linkedCaseTitles },
  };
};
