import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { serviceShapesWithoutWhite } from "../assets/shapes/services/shapeImports";
import Header from "./Header";

class HomeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }
  render() {
    return (
      <div>
        <Header onMenuToggle={this.toggleMenu} headerBG="beige" />
        <section className={`home-hero`}>
          <div className={`home-hero-pagelead`}>
            <div className={`home-hero-pagelead__info`}>
              <h1 className={`home-hero-pagelead__info-title`}>
                Wij ontwerpen<br></br>leerervaringen
              </h1>
              <p className={`home-hero-pagelead__info-lead`}>
                omdat we geloven dat krachtig leren<br></br>een groot verschil
                maakt.
              </p>
              <div className={`home-hero-pagelead__info-btns`}>
                <Link
                  className={`home-hero__btn btn-green btn-green--right`}
                  to="edu"
                >
                  For education<i className="icon-arrow-right"></i>
                </Link>
                <Link
                  to={`/workplace`}
                  className={`home-hero__btn btn-green btn-green--right`}
                >
                  For workplaces<i className="icon-arrow-right"></i>
                </Link>
              </div>
            </div>
            <i className="icon-arrow-down"></i>
          </div>
          <div className="shapes">
            <img
              src={serviceShapesWithoutWhite.strategy}
              className="serviceShape shapeStrategy"
              alt=""
            />
            <img
              src={serviceShapesWithoutWhite.productDesign}
              className="serviceShape shapeProductDesign"
              alt=""
            />
            <img
              src={serviceShapesWithoutWhite.contentCreation}
              className="serviceShape shapeContentCreation"
              alt=""
            />
            <img
              src={serviceShapesWithoutWhite.guidance}
              className="serviceShape shapeGuidance"
              alt=""
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(HomeHero);
