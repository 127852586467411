import React, { Component, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { changeProduct } from "../../redux/actions/actions";
import WorkplaceBusinessGames from "./products/WorkplaceBusinessGames";
import WorkplaceInstructievideos from "./products/WorkplaceInstructievideos";
import WorkplaceLeercampagnes from "./products/WorkplaceLeercampagnes";
import WorkplaceLeerpaden from "./products/WorkplaceLeerpaden";
import WorkplaceLeervisuals from "./products/WorkplaceLeervisuals";
import WorkplaceMicrolearnings from "./products/WorkplaceMicrolearnings";
import WorkplaceOpleidingstrajecten from "./products/WorkplaceOpleidingstrajecten";
import WorkplaceTestsQuizzen from "./products/WorkplaceTestsQuizzen";

const WorkplaceProduct = (props) => {
  const products = useSelector((state) => state.products.job);
  const dispatch = useDispatch();
  const currentUrl = useLocation().pathname;
  const productIndex = currentUrl.indexOf("producten") + 10;
  useLocation();

  useEffect(() => {
    if (currentUrl.slice(productIndex, currentUrl.length).slice(-1) === "/") {
      dispatch(
        changeProduct(
          currentUrl.slice(productIndex, currentUrl.length).split("/")[0]
        )
      );
    } else {
      dispatch(
        changeProduct(currentUrl.slice(productIndex, currentUrl.length))
      );
    }
  }, []);

  return (
    <div>
      <Header headerBG={"lightblue"}></Header>
      <Helmet titleTemplate="Oetang | Workplace - %s"></Helmet>
      <Routes>
        <Route exact path="/" element={<WorkplaceBusinessGames />} />
        <Route
          exact
          path={`/${products[0].titleInCode}`}
          element={<WorkplaceBusinessGames />}
        />
        <Route
          exact
          path={`/${products[1].titleInCode}`}
          element={<WorkplaceMicrolearnings />}
        />
        <Route
          exact
          path={`/${products[2].titleInCode}`}
          element={<WorkplaceOpleidingstrajecten />}
        />
        <Route
          exact
          path={`/${products[3].titleInCode}`}
          element={<WorkplaceLeerpaden />}
        />
        <Route
          exact
          path={`/${products[4].titleInCode}`}
          element={<WorkplaceInstructievideos />}
        />
        <Route
          exact
          path={`/${products[5].titleInCode}`}
          element={<WorkplaceLeervisuals />}
        />
        <Route
          exact
          path={`/${products[6].titleInCode}`}
          element={<WorkplaceTestsQuizzen />}
        />
        <Route
          exact
          path={`/${products[7].titleInCode}`}
          element={<WorkplaceLeercampagnes />}
        />
      </Routes>
    </div>
  );
};

export default WorkplaceProduct;
