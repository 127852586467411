import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import WorkplaceHome from "./WorkplaceHome";
import WorkplaceService from "./WorkplaceService";
import WorkplaceProduct from "./WorkplaceProduct";
import Helmet from "react-helmet";

class Workplace extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<WorkplaceHome />} />
          <Route path="/diensten/*" element={<WorkplaceService />} />
          <Route path="/producten/*" element={<WorkplaceProduct />} />
        </Routes>
      </div>
    );
  }
}

export default Workplace;
