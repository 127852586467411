import React, { Component } from "react";

class BtnCircle extends Component {
  render() {
    return (
      <span className="btn-circle">
        <i className="icon-arrow-right"></i>
      </span>
    );
  }
}

export default BtnCircle;
