import { connect } from "react-redux";
import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Motto from "../../../components/Motto";
import ServiceQuickNav from "../../../components/ServiceQuickNav";
import ServiceProces from "../../../components/ServiceProces";
import ServiceHero from "../../../components/ServiceHero";
import LinkedCases from "../../../components/LinkedCases";
import Helmet from "react-helmet";

function JobBegeleiding(props) {
  const { services } = props;
  return (
    <div>
      <Helmet>
        <title>
          {services[4].title
            .replace(/(<([^>]+)>)/gi, "")
            .replace("&shy;", "")
            .replace("&#8209;", "-")}
        </title>
        <meta
          name="description"
          content={services[4].lead
            .replace(/(<([^>]+)>)/gi, "")
            .replace("&shy;", "")}
        ></meta>
        <meta name="keywords" content={services[4].keywords}></meta>
      </Helmet>
      <ServiceHero activeService={services[4]}></ServiceHero>
      <Motto source={services[4].motto}></Motto>
      <ServiceProces activeService={services[4]}></ServiceProces>
      <ServiceQuickNav activeService={services[4].title}></ServiceQuickNav>

      <LinkedCases
        title="Een greep uit <br/>onze <span>realisaties</span>"
        caseTitles={services[4].linkedCases.cases}
      ></LinkedCases>
      <Footer cta={services[4].footerCta}></Footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: state.services.job,
});

export default connect(mapStateToProps, null)(JobBegeleiding);
