import React, { Component, useEffect } from "react";
import Header from "../components/Header";
import aap from "../assets/logos/logo-oetang_aap-groen-wit.svg";

import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const Error = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Header headerBG={"green"} />
      <Helmet>
        <title>404</title>
        <meta
          name="description"
          content="Oetang is een multidisciplinaire ontwerpstudio van leerervaringen. We werken volgens de methode van Learning Experience Design (LXD) waarbij we learner centered aan de slag gaan om leerproducten en leeroplossingen te ontwerpen. We doen dit zowel voor educatieve als professionele leeromgevingen."
        ></meta>
        <meta
          name="keywords"
          content="learning, leerervaring, design, leren, lxd, leerproduct, educatie, workplace, business, learning &amp; development, onderwijs, erfgoed, lifelong learning, learning experience design"
        ></meta>
      </Helmet>

      {/*<Header onMenuToggle={this.toggleMobileMenu} headerBG="green" />
       */}
      <section className="error">
        <div className="error__wrapper">
          <div>
            <img className="logo__aap" src={aap} alt="Oetang" />
            <h1>Oops.</h1>
          </div>
          <h2>Deze pagina werd niet gevonden.</h2>
          <a className="btn-green btn-green--right btn-green--404" href="#/">
            naar home <i className="icon-arrow-right"></i>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Error;
