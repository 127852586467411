import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <HashRouter basename="/">
        <Provider store={store}>
          <App />
        </Provider>
      </HashRouter>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
