import { eduProductIcons } from "../../assets/icons/iconImports";
import { workplaceProductIcons } from "../../assets/icons/iconImports";
import { serviceShapesOnWhite } from "../../assets/shapes/services/shapeImports";
import { serviceHeroImgs } from "../../assets/imgs/services/ServiceImgImports";
import { caseTiles } from "../../assets/imgs/cases/tiles/caseTileImports";

export const initialState = {
  activeSector: "",
  isMenuOpen: false,
  services: {
    activeService: "Onderzoek",
    edu: [
      {
        id: 1,
        title: "Onderzoek",
        titleInCode: "onderzoek",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, onderzoek",
        iconOnWhite: serviceShapesOnWhite.research,
        icon: "",
        path: "/edu/diensten/onderzoek",
        cardText:
          "Informatie en inzichten sprokkelen via goeie research: dat maakt een verschil voor het educatief product.",
        lead: "We brengen de leernoden, jouw leerinhouden en de leercontext in kaart. Een goed onderzoek, voor betere leerproducten.",
        heroImg: serviceHeroImgs[0],
        motto:
          "Je kan pas maken wat je moet maken als je precies weet waarom, voor wie en waarover. <span>Goede research maakt een verschil.</span>",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Verfijnen van je vraag",
              bodyText:
                "Bij de start van een nieuw project willen we de organisatie en de context rond de aanvraag goed begrijpen. We brengen samen de wensen in kaart en (her)formuleren de doelstellingen van het project . We kunnen <em>critical friend</em> zijn, als je dat wil.",
            },
            {
              title: "Gebruikers&shy;onderzoek",
              bodyText:
                "We helpen je om jouw doelgroep te analyseren door echt met kinderen, jongeren, leerkrachten, begeleiders ... in dialoog te gaan. Dit levert inzichten, leernoden en eerste ideeën op.",
            },
            {
              title: "Inhouden verkennen",
              bodyText:
                "Alle inhouden rond het onderwerp verzamelen en clusteren: zo krijg je grip op het geheel. <em>Love it!</em> Welke  leerdoelen en bijhorende leerinhouden leg je vast? We duiken voor jou in curricula, doen markt- en bronnenonderzoek, praten met de experts ...",
            },
            {
              title: "Mapping van leercontext",
              bodyText:
                "Educatieve leeromgevingen kunnen heel gevarieerd zijn. In een school, een museum, een bibliotheek, of tijdens een evenement? Formeel of informeel? We denken samen na over de context en de leerkansen. Oetang helpt je dit te verbeelden.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["123-archeologie", "edo", "abc-muco"],
        },
        footerCta: "Hier komt een CTA <span>gericht op onderzoek</span>",
      },
      {
        id: 2,
        title: "Strategie",
        titleInCode: "strategie",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, strategie",
        iconOnWhite: serviceShapesOnWhite.strategy,
        icon: "",
        path: "/edu/diensten/strategie",
        cardText:
          "Bewust en ambitieus een didactische leerstrategie uitstippelen, als kompas voor jouw leerproducten.",
        lead: "We maken heldere keuzes voor jouw leerproduct. Gestoeld op visie en onderzoek, en gedragen door de betrokkenen.",
        heroImg: serviceHeroImgs[1],
        motto:
          "Een educatief product heeft deugd van duidelijke keuzes. De richting bepalen we samen: <span>doelgericht en gebruikersgericht</span>.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Didactische keuzes",
              bodyText:
                "Kwaliteitsvolle leerervaringen zijn didactisch onderbouwd. Oetang heeft een brede didactische expertise en stelt een didactische strategie op voor jouw project. Niet als saus, maar als fundament.",
            },
            {
              title: "Leervorm bepalen",
              bodyText:
                "Vaak heb je je als opdrachtgever al een beeld vooropgesteld van het eindproduct. Op basis van het onderzoek, bevestigen we dit of stellen we andere leervormen voor. Weloverwogen, omdat ze moeten werken.",
            },
            {
              title: "Duidelijke scope",
              bodyText:
                "Doen we. Doen we niet. Doen we nóg niet. Keuzes maken voor je product is vaak lastig. Oetang helpt je tot een heldere afbakening te komen. Zo werken we  samen vanuit scherpe keuzes aan het leerproduct. Binnen het budget, dat ook.",
            },
            {
              title: "Valideren met betrokkenen",
              bodyText:
                "De keuzes die we samen maken, worden gefeedbackt en gevalideerd. Oetang faciliteert deze stap met respect voor de diversiteit van de betrokkenen. Gedragen keuzes? Check!",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["erfgoedklasbakken", "kov-zill", "edo"],
        },
        footerCta: "Hier komt een CTA <span>gericht op strategie</span>",
      },
      {
        id: 3,
        title: "Product design",
        titleInCode: "product-design",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, product design",
        iconOnWhite: serviceShapesOnWhite.productDesign,
        icon: "",
        path: "/edu/diensten/product-design",
        cardText:
          "Van concept tot realisatie een leerervaring ontwikkelen: op maat van jouw leerders, jong of ouder.",
        lead: "We ontwikkelen impactvolle leerervaringen in allerlei vormen en maten. Daarin zetten we jouw leerders altijd centraal.",
        heroImg: serviceHeroImgs[2],
        motto:
          "Voor jouw educatieve project <span>een echte leerervaring ontwerpen</span>, dat is de uitdaging waar we van houden.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Concept verzinnen",
              bodyText:
                "Hoe bedenk je unieke educatieve concepten die effectief en motiverend zijn voor jouw doelgroep ? Oetang neemt je mee in de brainstorms en conceptontwikkeling, gericht op een integrale leerervaring.",
            },
            {
              title: "Prototyping en testing",
              bodyText:
                "Testen is weten. Om zeker te zijn dat een leerconcept succesvol zal zijn, stap je best naar de gebruikers met een prototype. Oetang organiseert die testings voor jou bij een goed gekozen testpubliek.",
            },
            {
              title: "UI en graphic design",
              bodyText:
                "De vormgeving van een leerproduct is een niet te onderschatten factor: het bepaalt mee de beleving. Ons multidisciplinair team heeft designers in huis met zowel een hybride als digitale mindset.",
            },
            {
              title: "Realisatie en productie",
              bodyText:
                "Een educatief product kan fysiek, hybride of digitaal zijn: dozen, kaartjes en lesmappen, e-tools,  websites ... Oetang ontzorgt je 100% hierbij: ook realisatie, productie en development zijn ons ding.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["texture", "conceptkaarten", "wel-in-je-vel"],
        },
        footerCta: "Hier komt een CTA <span>gericht op product design</span>",
      },
      {
        id: 4,
        title: "Content&shy;creatie",
        titleInCode: "contentcreatie",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, content creation, contentcreatie",
        iconOnWhite: serviceShapesOnWhite.contentCreation,
        icon: "",
        path: "/edu/diensten/contentcreatie",

        cardText:
          "Leerinhouden didactisch uitwerken met de grootste zorg voor instructietaal en grafische vorm.",

        lead: "We duiken jouw inhouden en thema's in en doen er echt iets mee. Opknippen, groeperen, schrijven, omzetten naar beeld of beweging ...",
        heroImg: serviceHeroImgs[3],
        motto:
          "Je leerervaringen versterken met inhoud waar je - euh - content van wordt: <span>betekenisvol en in samenspel met de vorm</span>.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Leerinhouden uitwerken",
              bodyText:
                "Vaak heb je als organisatie al veel content op de plank liggen. Maar is die klaar voor jouw leerders? Is de inhoud multimediaal genoeg? Is ze voldoende concreet of narratief? Oetang werkt nieuwe leerinhouden uit en/of herwerkt bestaande.",
            },
            {
              title: "Leermateriaal uitbreiden",
              bodyText:
                "Wanneer een leerproduct al even in gebruik is, wil je misschien nieuwe inhouden toevoegen. Zo zorg je voor zinvolle uitbreidingen en actualisatie van het bestaande product. Wij helpen je ook heel graag bij jouw inhoudelijke 2.0!",
            },
            {
              title: "Instructies uitschrijven",
              bodyText:
                "Hoe schrijf je helder, motiverend en gericht op zelfsturing? Instructional design zit in ons bloed. Van kleine opdrachtjes voor kinderen tot handleidingen voor begeleiders.",
            },
            {
              title: "Een ander medium",
              bodyText:
                "Afhankelijk van de doelgroep kan het noodzakelijk zijn om leerinhouden in andere mediale vormen aan te bieden. Oetang beschikt over de expertise om de transfer of combinatie te maken tussen tekst, beeld, foto, film, geluid ...",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["happy-snacks", "synthese-animaties", "cirkelhelden"],
        },
        footerCta: "Hier komt een CTA <span>gericht op content creatie</span>",
      },
      {
        id: 5,
        title: "Begeleiding",
        titleInCode: "begeleiding",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, begeleiding, vorming",
        iconOnWhite: serviceShapesOnWhite.guidance,
        icon: "",
        path: "/edu/diensten/begeleiding",
        cardText:
          "Coaching en implementatiehulp om de leerervaringen goed en duurzaam te lanceren.",

        lead: "Niks is 'af' tot het goed en voldoende gebruikt wordt. We begeleiden, coachen of helpen implementeren op het tempo van je educatieve organisatie.",
        heroImg: serviceHeroImgs[4],
        motto:
          "Leren is veranderen. Dat vraagt soms wat tijd. Als het nodig is helpen we je graag met het <span>verankeren van de leerproducten</span>.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Training",
              bodyText:
                "Sommige leerproducten hebben een begeleider nodig. Oetang bedenkt samen met jou hoe we die begeleiders kunnen ondersteunen. Via een training met begeleidingstips ondersteunen we de educatieve collega of leerkracht om met het leerproduct te werken.",
            },
            {
              title: "Implementatie ondersteunen",
              bodyText:
                "Bepaalde leerproducten duurzaam geïntegreerd krijgen in je bredere educatieve werking vraagt een goede implementatie. We voorzien dus graag ook implementatieondersteuning bij de producten die we ontwikkelen.",
            },
            {
              title: "Intervisie en nazorg",
              bodyText:
                "Hoe kun je de rijke ervaringen van de begeleiders of gebruikers van je leerproduct delen? Hoe kunnen ze van elkaar leren? Een intervisie- of nazorgmoment heeft een versterkend effect. Oetang helpt je bij het ontwerp en/of bij de facilitatie ervan.",
            },
            {
              title: "Wegwijs in LXD ",
              bodyText:
                "Learning Experience Design is een vrij jonge discipline. Wil je in je eigen educatieve werking ook LXD inzetten, dan geven we je graag de tips en tricks via een opleiding of begeleiding. Gebruik onze expertise om zelf een learning designer te worden.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["texture", "erfgoedklasbakken", "groeitjes-uit-kazou"],
        },
        footerCta: "Hier komt een CTA <span>gericht op begeleiding</span>",
      },
    ],
    job: [
      {
        id: 1,
        title: "Onderzoek",
        titleInCode: "onderzoek",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, onderzoek",
        iconOnWhite: serviceShapesOnWhite.research,
        icon: "",
        path: "/workplace/diensten/onderzoek",
        cardText:
          "Inzichten en data verzamelen via research: dat maakt een verschil voor het leerproduct.",
        lead: "We brengen - met een frisse blik - de leernoden van jouw professionele leeromgeving in kaart.",
        heroImg: serviceHeroImgs[0],
        motto:
          "De L&D-noden en leercultuur van de organisatie in kaart brengen: <span>goede research maakt een verschil</span>.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "De noden van de organisatie",
              bodyText:
                "Bij de start van een nieuw learning project willen we de bedrijfsvraag en -context goed begrijpen. We brengen samen de wensen in kaart en (her)formuleren de doelstellingen.",
            },
            {
              title: "Leernoden medewerkers",
              bodyText:
                "We helpen je om de noden van je medewerkers te analyseren door met verschillende profielen in dialoog te gaan. Dit levert noodzakelijke data en inzichten op voor het leerproduct.",
            },
            {
              title: "Begrip van de leercultuur",
              bodyText:
                "De animo rond professionalisering, talentontwikkeling, en levenslang (samen) leren bepalen mee de L&D-cultuur van een bedrijf. Samen proberen we jullie leercultuur en de kansen te benoemen.",
            },
            {
              title: "De thema's verkennen",
              bodyText:
                "Binnen elke organisatie is heel veel expertise aanwezig. We leggen deze interne expertise samen met een breder onderzoek om tot een duidelijk inzicht te komen van wat de medewerkers moeten leren ... en waarom.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "edo"],
        },
        footerCta: "Hier komt een CTA <span>gericht op onderzoek</span>",
      },
      {
        id: 2,
        title: "Strategie",
        titleInCode: "strategie",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, strategie",
        iconOnWhite: serviceShapesOnWhite.strategy,
        icon: "",
        path: "/workplace/diensten/strategie",
        cardText:
          "Vanuit een L&D-visie een krachtige leerstrategie uitstippelen voor het leerproduct.",
        lead: "We bepalen de strategie van het leerproduct en verbinden dit aan een L&D-visie, eigen aan de organisatie.",
        heroImg: serviceHeroImgs[1],
        motto:
          "De beste leerproducten voor jouw organisatie vertrekken uit een <span>heldere L&D-visie en een doelgerichte strategie</span>.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "L&D-visie en -beleid",
              bodyText:
                "Als organisatie wil je duurzame leerervaringen ontwikkelen die zich inschrijven in de visie en het beleid van de organisatie. Oetang adviseert je en daagt je uit om dit toekomstgericht te doen.",
            },
            {
              title: "Scope en targets",
              bodyText:
                "Een resultaatgerichte afbakening maken is essentieel  voor een goed product. Tegelijk zijn we samen al alert voor opschaling of verdere fasering.",
            },
            {
              title: "Leervorm en didactiek",
              bodyText:
                "Tijdens de strategische en creatieve keuze voor de leervorm en didactiek, stellen we het leereffect bij jouw medewerkers centraal. We stellen een leeroplossing voor.",
            },
            {
              title: "Validatie",
              bodyText:
                "De keuzes die we samen maken, worden gefeedbackt en gevalideerd. Oetang faciliteert deze stap met respect voor de diversiteit van de betrokkenen. Gedragen keuzes? Check!",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op strategie</span>",
      },
      {
        id: 3,
        title: "Product design",
        titleInCode: "product-design",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling",
        iconOnWhite: serviceShapesOnWhite.productDesign,
        icon: "",
        path: "/workplace/diensten/product-design",
        cardText:
          "Van concept tot realisatie een leerervaring ontwikkelen: op maat van jouw medewerkers.",
        lead: "We bedenken en ontwerpen leerervaringen in allerlei vormen en maten voor jouw medewerkers.",
        heroImg: serviceHeroImgs[2],
        motto:
          "<span>Impactvolle leerervaringen ontwerpen</span> voor jouw professionals is wat ons drijft als Learning Designers.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Concept verzinnen",
              bodyText:
                "Hoe bedenk je een uniek en motiverend leerconcept dat medewerkers kennis en skills bijbrengt? Oetang neemt je mee in de brainstorms en conceptontwikkeling, gericht op een totale leerervaring met jouw identiteit.",
            },
            {
              title: "Prototyping en testing",
              bodyText:
                "Testen is weten. Om zeker te zijn dat een leerconcept impactvol zal zijn, trekken we naar je medewerkers om vroege prototypes te testen. Daar leren we dan weer zelf uit.",
            },
            {
              title: "UI en graphic design",
              bodyText:
                "De vormgeving van een leerproduct is een niet te onderschatten factor: het bepaalt mee de beleving. Ons multidisciplinair team heeft designers in huis met zowel een hybride als digitale mindset.",
            },
            {
              title: "Realisatie en productie",
              bodyText:
                "Een professioneel leerproduct kan fysiek, hybride of digitaal zijn. Oetang ontzorgt je 100% hierbij: ook realisatie, productie en development zijn ons ding.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op product design</span>",
      },
      {
        id: 4,
        title: "Content&shy;creatie",
        titleInCode: "contentcreatie",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, content creation, contentcreatie",
        iconOnWhite: serviceShapesOnWhite.contentCreation,
        icon: "",
        path: "/workplace/diensten/contentcreatie",

        cardText:
          "Leerinhouden van jouw organisatie uitwerken met de grootste zorg voor instructietaal en grafische vorm.",

        lead: "We verdiepen ons in de thema's van de organisatie en maken er jouw leercontent van.",
        heroImg: serviceHeroImgs[3],
        motto:
          "Jouw learning content bedenken of herdenken, <span>helemaal op maat van de medewerkers</span>. En lekker strak.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Content uitwerken en vormgeven",
              bodyText:
                "Vaak heb je als bedrijf al veel content op de plank liggen. Maar is die klaar voor jouw medewerkers?  We maken er goede, rijke en interactieve content van. Of we starten vanaf nul.",
            },
            {
              title: "Nieuwe modules ontwerpen",
              bodyText:
                "Wanneer een leerproduct of -platform al even in gebruik is, wil je nieuwe inhouden toevoegen. Zo zorgen we samen voor zinvolle actualisatie, en dat is noodzakelijk in snel veranderende bedrijfscontexten.",
            },
            {
              title: "Instructional design",
              bodyText:
                "Het is een uitdaging om medewerkers te activeren tijdens het leerervaring. Een sleutel daartoe is wervend en wervelend instructional design.",
            },
            {
              title: "Ander medium",
              bodyText:
                "De kans is groot dat binnen de organisatie vooral veel informatie circuleert in tekstvorm. Oetang transfereert die naar andere mediavormen, zodat ze de medewerkers extra aanspreken.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op content creatie</span>",
      },
      {
        id: 5,
        title: "Begeleiding",
        titleInCode: "begeleiding",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, begeleiding",
        iconOnWhite: serviceShapesOnWhite.guidance,
        icon: "",
        path: "/workplace/diensten/begeleiding",
        cardText:
          "Implementatieondersteuning om de leerervaringen te verankeren in de organisatie.",

        lead: "We begeleiden, coachen of helpen implementeren op het tempo van jouw medewerkers en L&D-project.",
        heroImg: serviceHeroImgs[4],
        motto:
          "Leren is een basis voor <span>groei en verandering</span> in een bedrijf. En dat vraagt een goede implementatie.",
        proces: {
          procesTitle: "",
          procesCards: [
            {
              title: "Training",
              bodyText:
                "Sommige leerproducten hebben een L&D'er of collega nodig als begeleider. Via een training met begeleidingstips ondersteunen we je om met het leerproduct te werken, bv. in de vorm van een train-de-trainer.",
            },
            {
              title: "Implementatie ondersteunen",
              bodyText:
                "Bepaalde leerproducten duurzaam geïntegreerd krijgen in je bredere L&D-werking vraagt een goede implementatie. We voorzien dus graag ook implementatieondersteuning bij de producten die we ontwikkelen.",
            },
            {
              title: "Intervisie en nazorg",
              bodyText:
                "We brengen medewerkers samen om te reflecteren over de leerervaringen. Via dialoog versterk en veranker je leereffect. Oetang faciliteert dit graag in samenspel met je L&D-dienst.",
            },
            {
              title: "Wegwijs in LXD ",
              bodyText:
                "Learning Experience Design is een vrij jonge discipline. Wil je in je eigen L&D- of HR-dienst ook LXD inzetten, dan geven we je graag de tips en tricks via een opleiding of begeleiding. Gebruik onze expertise om zelf een learning designer te worden.",
            },
          ],
        },
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op begeleiding</span>",
      },
    ],
  },
  products: {
    activeProduct: "",
    edu: [
      {
        id: 0,
        title: "Educatieve pakketten",
        label: "lespakket",
        icon: eduProductIcons[0],
        titleInCode: "educatieve-pakketten",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, educatieve pakketten",
        path: "/edu/producten/educatieve-pakketten",
        cardText: "Gevarieerd, samenhangend en thematisch.",
        lead: "Ze komen in alle vormen en maten: van dozen en mappen, tot websites met alle nodige spullen. Oetang ontwerpt totaalpakketten rond al jouw educatieve thema's.",
        heroImg: eduProductIcons[0],
        baseline:
          "Gevarieerd, <span>samenhangend en thematisch</span>, met een strik errond",
        baseText:
          "Voor organisaties zoals onderwijs&shy;partners, cultuur&shy;instellingen of ngo's, is een educatief pakket een vertrouwd format.  Jouw onder&shy;werp wordt in de breedte en diepte uit&shy;gewerkt via verschillende inter&shy;actieve werk&shy;vormen, met aandacht voor de diverse doelgroepen. <br>Een educatief pakket gaat voor samen&shy;hangende leerervaring met een duidelijk thema en verhaal. <br>Het pakket bevat alle ondersteuning en materialen die de leerkracht of begeleider nodig heeft om er comfortabel mee te starten.",

        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["123-archeologie", "geluksdriehoek", "abc-muco"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 1,
        title: "Educatieve spellen",
        label: "spel",
        titleInCode: "educatieve-spellen",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, educatieve spellen",
        icon: eduProductIcons[1],
        path: "/edu/producten/educatieve-spellen",
        cardText: "Die extra motivatie en drive bij spelend leren.",
        lead: "<em>Game based</em> principes toevoegen aan leerervaringen kan effectief en <em>fun</em> zijn. Oetang ontwikkelt educatieve spellen met uitdagende doelen. Jouw leerders laten spelen, en jouw spelers laten leren.",
        heroImg: eduProductIcons[1],
        baseline:
          "Die extra <span>motivatie en drive</span><br>bij spelend leren.",
        baseText:
          "Spelsystemen kan je inzetten om tot leren en veranderen te komen. Via spel verhogen we de leermotivatie en kunnen we verschillende types van doelen bereiken bij jouw educatief publiek. Bord&shy;spelen, kaart&shy;spelen, oplossings&shy;gerichte games ... <em>You name it, we game it</em>. <br>Zowel in fysieke als hybride games focussen we op een duidelijk <em>challenge</em>, een mee&shy;slepend verhaal, goeie feedback en - ok dan - een beloning. Gevolg? De drive om te blijven leren, samen met de andere spelers.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["wel-in-je-vel", "billenkarrenspel", "texture"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 2,
        title: "Toolkits",
        titleInCode: "toolkits",
        label: "toolkit",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, educatieve toolkits, toolkits",
        icon: eduProductIcons[2],
        path: "/edu/producten/toolkits",
        cardText:
          "Handig gereedschap om samen te leren, reflecteren en brainstormen.",
        lead: "Via toolkits zet je de leerders snel, gestructureerd en zelfstandig aan tot actie. Oetang geeft jouw leerders zo echt iets in handen om samen te leren, te reflecteren of te brainstormen. Alles kits.",
        heroImg: eduProductIcons[2],

        baseline:
          "<span>Handig gereedschap</span><br>om samen te leren, <br>reflecteren en creëren",
        baseText:
          "Wil jij iets opzetten waarbij je deel&shy;nemers zelf vanuit interactie en reflectie tot inzichten of ideeën komen? Dan is een toolkit mogelijks de ideale leeroplossing. <br>Een toolkit is - letterlijk - een verzameling instrumenten; zoals kaartjes, templates, stickers ... <br>Sommige toolkits faciliteren één proces, bij andere pluk je het instrument of de methodiek op basis van wat je op dat moment nodig hebt. <br>Oetang bedenkt deze toolkits helemaal op maat van jouw inhouden of processen.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["happy-snacks", "groeitjes-uit-kazou", "conceptkaarten"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 3,
        title: "Workshops",
        titleInCode: "workshops",
        label: "workshop",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, workshops",
        icon: eduProductIcons[3],
        path: "/edu/producten/workshops",
        cardText:
          "Hands-onwerkvormen voor de workshops die jij straks begeleidt.",
        lead: "Samen met anderen leren, via uitwisseling en interactie, is een unieke leerervaring. Oetang ontwikkelt vanuit haar didactische knowhow het verloop van jouw workshops.",
        heroImg: eduProductIcons[3],

        baseline:
          "<span>Hands-onwerkvormen</span> voor <br>de workshops en sessies, <br>die jij straks begeleidt",
        baseText:
          "In contexten zoals onderwijs, cultuur, erfgoed, jeugd, milieu ... is 'de work&shy;shop' al lang een gevestigde waarde. <br> Maar ... hoe betrek je kinderen, jongeren of volwassen actief? Hoe zorg je voor een even&shy;wicht tussen verwerven en verwerken? En wat met differentiatie? <br>Oetang is gepokt en gemazeld in het ontwikkelen van workshops. Wij bedenken workshops voor jou; en jij kan ze straks begeleiden. <br>Een doe-workshop? Een interactieve presentatie? Iets met veel groeps&shy;dynamiek? ... <em>Oetang makes it work ... shop</em>.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["cirkelhelden", "heldenflix", "kunstparcours"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 4,
        title: "Blended en e&#8209;leer&shy;paden",
        titleInCode: "leerpaden",
        label: "leerpad",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, leerpaden, blended learning, e-leerpaden, blended leerpaden",
        icon: eduProductIcons[4],
        path: "/edu/producten/leerpaden",
        cardText: "Een stapsgewijze<br>leerervaring <br>met een plan.",
        lead: "Voor stevigere leerinhouden die je (deels) zelfsturend en tijdsonafhankelijk wil aanbieden, zijn digitale leerpaden een oplossing. Oetang bedenkt samen met jou de route en de invulling van de stappen.",
        heroImg: eduProductIcons[4],

        baseline:
          "<span>Stap voor Stap, </span><br>een leerervaring <br>met een plan",
        baseText:
          "E-learning maakt (nieuwe) werkvormen mogelijk om zelf&shy;standig en gestructureerd te leren. Ook in combinatie met live leer&shy;ervaringen. <br>Door leerinhouden te structureren en reliëf aan te brengen in de moeilijkheid en volg&shy;tijdelijkheid, ontstaan paden. Oetang helpt jou om hierover digitaal en gedifferentieerd te denken. <br>Vanuit onze expertise in e-learning stippelen we mee de routes uit en kiezen we voor elke stap de juiste leervorm, ook hybride. <br>Dat kan binnen bestaande systemen die jullie al gebruiken of binnen een systeem dat we adviseren of samen onderzoeken.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["erfgoedklasbakken", "edo", "breingravers"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 5,
        title: "Educatieve e&#8209;tools",
        titleInCode: "educatieve-e-tools",
        label: "e-tool",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, educatieve e-tools, e-tools, tools",
        icon: eduProductIcons[5],
        path: "/edu/producten/educatieve-e-tools",
        cardText: "Handige leertools voor <br>grote of kleinere <br>schermen.",
        lead: "Bepaalde specifieke inhouden of werkvormen vragen een online tool op maat. Oetang bouwt die: voor op het digibord in de klas, of voor kleinere formaten.",
        heroImg: eduProductIcons[5],

        baseline:
          "<span>Handige</span> leertools voor <br>grote of kleinere <br>schermen",
        baseText:
          "Zo'n online tool die goed doet wat ie moet doen. Functioneel, compact, uniek en handig voor de gebruiker. <br>Oetang onderzoekt en conceptualiseert e-tools die jouw leerders helpen bij een specifieke leertaak. <br>Bijvoorbeeld e-tools om iets te trainen, te ontdekken, te puzzelen of te presenteren ... <br>Dat kan voor gebruik in groep, bv. op een smartboard maar evengoed voor individueel gebruik op laptop of tablet.",

        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["cirkelhelden", "kov-zill", "fonemi"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 6,
        title: "Instructie&shy;video's",
        titleInCode: "instructievideos",
        label: "instructievideo",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang leren, learning experience design, instructievideo, explainer video",
        icon: eduProductIcons[6],
        path: "/edu/producten/instructievideos",
        cardText: "Helemaal mee in <br>enkele minuten.",
        lead: "Je maakte er zelf zeker al 's gebruik van: een instructievideo, <em>explainer</em> of <em>tutorial</em>. Oetang maakt ze met een didactisch oog: montages van beeld, tekst en stem zetten diverse doelgroepen aan het leren.",
        heroImg: eduProductIcons[6],

        baseline:
          "<span>Helemaal mee</span> in <br>enkele minuten. <br>Press play.",
        baseText:
          "Je leerinhouden durven zien als opbouwende scènes op de tijdslijn. Daar schuilt de kracht van video in: knippen, inzoomen, opbouwen en animeren om de ideale didactische houvast te creëren voor de kijker. <br>Door het unieke samenspel van bewegend beeld, geluid, voice-over en tekst breng je de leer&shy;inhouden op een andere manier tot leven in instructie&shy;video's en kennisclips. Dat zorg voor een groot engagement van de leerder.",

        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: [
            "grammatica-animaties",
            "synthese-animaties",
            "erfgoedklasbakken",
          ],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 7,
        title: "Info&shy;graphics en leer&shy;visuals",
        titleInCode: "leervisuals",
        label: "leervisual",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, educatie, onderwijs, erfgoed, levenslang lerqen, learning experience design, leervisual, infographic",
        icon: eduProductIcons[7],
        path: "/edu/producten/leervisuals",
        cardText: "Aanschouwelijk leren <br>met minder woorden.",
        lead: "Iets aanschouwelijk en helder overbrengen, vraagt een prachtig huwelijk tussen vorm en inhoud. Oetang zet jouw informatie om in <em>learning visuals</em> met impact.",
        heroImg: eduProductIcons[7],

        baseline:
          "Heldere en inzichtelijke visuals: <br>leren <span>met minder woorden</span>",
        baseText:
          "Iedere leerder is anders, en iedere leerder raakt wel 's de weg kwijt. Zeker als de informatie moeilijk en veel om te verwerken is. <br>Het slim ordenen, ontvetten en visualiseren van infor&shy;matie, ondersteunt het leerproces enorm. <br>Posters, schema's, presentaties en leerwanden zijn visuele concepten die het leren aan&shy;schouwelijk maken: ze begeleiden je van geheel naar detail, en omgekeerd. <br>Wij maken de visuele vertaalslag met oog voor vorm, inhoud én architectuur.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["passiebad", "123-archeologie", "edo"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
    ],
    job: [
      {
        id: 0,
        title: "Business games en toolkits",
        icon: workplaceProductIcons[0],
        titleInCode: "business-games",
        label: "business game",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, business games, serious gaming, serious games",
        path: "/workplace/producten/business-games",
        cardText: "Samen spelen en reflecteren om te leren.",
        lead: "Leren <i>on the job</i> effectief en leuk maken kan: via speelse leerervaringen of handige toolkits motiveren we teams.",
        heroImg: workplaceProductIcons[0],
        baseline:
          "Samen, <span>speels, reflectief.</span><br>Rond een tafel, of net niet.",
        baseText:
          "Hoe komen deelnemers vanuit speelse interactie en gedeelde reflectie tot leren en veranderen? Via spel verhogen we de leer&shy;motivatie: bord&shy;spelen, kaart&shy;spelen, oplossings&shy;gerichte games ... <em>You name it, we game it</em>.<br>Via toolkits faciliteren we het denk- en reflectie&shy;proces met instrumenten zoals kaartjes, templates, stickers ... <br>Jouw medewerkers trainen zo kennis, skills en attitudes terwijl ze actief meedenken én <em>fun</em> hebben. Oetang bedenkt competitieve of coöperatieve business games en toolkits met één of meerde methodieken, alles op maat van jouw inhouden of processen ... En daar zal nog lang over nagekaart worden in jouw organisatie.",

        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "abc-muco"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 1,
        title: "Micro&shy;learnings",
        titleInCode: "microlearnings",
        label: "microlearning",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, microlearning, bitesized learning",
        icon: workplaceProductIcons[1],
        path: "/workplace/producten/microlearnings",
        cardText: "Behapbare leermomenten <br>als tussendoortje of snack.",
        lead: "Leren in kleine porties zit in de lift omdat het de ideale leeroplossing is om gefocust en haalbaar te leren tijdens korte momenten.",
        heroImg: workplaceProductIcons[1],
        baseline:
          "<span>Behapbare</span> leermomenten <br>als tussendoortje of snack",
        baseText:
          "Microlearnings maken het mogelijk om net die kleine momenten tussendoor te besteden aan leren. Of het nu gaat over een mobiele tool of een korte, terugkerende leerinterventie online (of: in de mailbox): microleeroplossingen hebben een grote impact.<br> Oetang bedenkt samen met jouw hoe we de koek in kleine betekenisvolle stukjes kunnen breken voor jouw medewerkers. Op die manier ontstaat een reeks behapbare en motiverende leer&shy;erva&shy;ringen die elkaar opvolgen tot alle leerdoelen zijn bereikt.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "synthese-animaties"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 2,
        title: "Opleidings&shy;trajecten",
        titleInCode: "opleidingstrajecten",
        label: "opleidingstraject",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, opleidingstraject",
        icon: workplaceProductIcons[2],
        path: "/workplace/producten/opleidingstrajecten",
        cardText:
          "<span>Kortere of langere</span><br>programma's, samen<br>met collega's.",
        lead: "Wanneer grotere volumes knowhow, expertise en skills moeten verworven worden, is een face-to-faceprogramma met collega's ideaal.",
        heroImg: workplaceProductIcons[2],

        baseline:
          "<span>Kortere of langere</span><br>programma's, samen<br>met collega's",
        baseText:
          "Het bereiken van ambitieuze doelstellingen of het aanbrengen van grotere thema's vergt een door&shy;dachte aanpak. Een gevarieerd en veranderings&shy;gericht opleidingstraject kan een oplossing zijn. Maar ... hoe spreid je de leer&shy;inhouden? Hoe betrek je de medewerkers? Waar differentieer je voor verschillende rollen of profielen? Hoe haal je het maximale uit de interactie?<br/> Oetang ontwerpt voor jouw organisatie even&shy;wichtige en activerende workshopreeksen en trainingstrajecten, zowel kortlopend als lang&shy;lopend.<br/>We besteden daarbij grote aandacht aan de werkvormen en de inhoud, en benutten de kracht van het samen leren optimaal. Oh ja ... blended kan ook.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 3,
        title: "E&#8209;leer&shy;paden",
        titleInCode: "leerpaden",
        label: "leerpad",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, leerpad, e-leerpad, blended, blended learning",
        icon: workplaceProductIcons[3],
        path: "/workplace/producten/e-leerpaden",
        cardText: "Stap voor stap<br>online leren, waar<br>en wanneer je wil.",
        lead: "Plaats- en tijdsonafhankelijk online of blended leren biedt krachtige voordelen voor jouw organisatie en diversiteit aan medewerkers.",
        heroImg: workplaceProductIcons[3],

        baseline:
          "<span>Stap voor stap</span><br>online leren, waar<br>en wanneer je wil",
        baseText: `De voordelen van e-learning hoeven we aan L&D-managers niet meer uit te leggen. Maar om er toch een paar op te sommen: zelf&shy;sturend, plaats- en tijdsonafhankelijk, schaal&shy;baar, herbruikbaar, opvolgbaar ... <br/>Oetang ontwikkelt in co-creatie met L&D- of HR-professionals e-leerpaden op maat, inclusief alle interactieve elementen. <br/><span style={{whiteSpace: "nowrap"}}>Wanneer de organisatie al beschikt over een <em>Learning Management System</em>, denken we mee na over hoe we die infrastructuur optimaal kunnen gebruiken. Wanneer je nog je eerste stappen in e-learning moet zetten, dan zoeken we graag mee naar een laag&shy;drem&shy;pelige instap of slimme keuze om aan die e-noden van de organisatie te voldoen.</span>`,
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "edo"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 4,
        title: "Instructie&shy;video's",
        titleInCode: "instructievideos",
        label: "instructievideo",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, instructievideo, explainer video",
        icon: workplaceProductIcons[4],
        path: "/workplace/producten/instructievideos",
        cardText: "Helemaal mee in enkele minuten.",
        lead: "Video inzetten voor complexere instructies of moeilijke theoretische concepten, werkt ondersteunend voor medewerkers.",
        heroImg: workplaceProductIcons[4],

        baseline:
          "<span>Helemaal mee</span> in <br>enkele minuten. <br>Press play.",
        baseText: `Complexere instructies, abstracte theoretische concepten of kaders, mobiliserende of urgente leer&shy;bood&shy;schappen ... Kennisclips of instructie&shy;video's lenen zich hier vaak voor. Via beeld, tekst en geluid  zetten we ze om in een helder narratief dat je vlot 'consumeert'.<br/><span style={{whiteSpace: "nowrap"}}>Oetang kiest ook hier voor een zeer doel&shy;gerichte en didactische benadering: we ontwerpen de clips zo dat de medewerkers zich engageren om effectief tot leren te komen. Dit kan gaan over één leervideo, maar evengoed over een reeks video's die samen een leerkanaal vormen.</span>`,

        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "synthese-animaties"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 5,
        title: "Info&shy;graphics en leer&shy;visuals",
        titleInCode: "leervisuals",
        label: "leervisual",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, leervisual, infographic",
        icon: workplaceProductIcons[5],
        path: "/workplace/producten/leervisuals",
        cardText: "Visueel en met weinig woorden, medewerkers activeren.",
        lead: "Door een krachtige grafische vormgeving kun je leerinhouden toegankelijk en activerend maken. In alle maten en vormen.",
        heroImg: workplaceProductIcons[5],

        baseline:
          "Visueel en <span>met weinig woorden</span> <br>medewerkers activeren",
        baseText: `Iedere medewerker leert anders. Iedere mede&shy;werker raakt ook wel 's de weg kwijt in de nieuwe informatie die hij of zij moet verwerken. <br/>Het slim ordenen, ontvetten en visualiseren van informatie, ondersteunt hun leerproces enorm.<br/>Posters, schema's, presentaties en leer&shy;wanden zijn visuele concepten die het leren aan&shy;schou&shy;welijk maken: ze begeleiden je van geheel naar detail, en omgekeerd. <br/>Wij maken de visuele vertaalslag met oog voor vorm, inhoud en architectuur. En vanuit de identiteit van jouw organisatie!`,
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "edo"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 6,
        title: "Tests en vragen&shy;lijsten",
        titleInCode: "tests-en-vragenlijsten",
        label: "vragenlijst",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, test, vragenlijst, quiz",
        icon: workplaceProductIcons[6],
        path: "/workplace/producten/tests-en-vragenlijsten",
        cardText: "Meetresultaten <br>om groeikansen<br>te ontdekken.",
        lead: "Meten is weten. Ook in jouw professionele leeromgeving is dat zo. Via tests, quizzen en bevragingen ontdek je waar medewerkers staan.",
        heroImg: workplaceProductIcons[6],

        baseline:
          "Meetresultaten gebruiken<br>om <span>verdere groeikansen</span><br>te ontdekken",
        baseText:
          "Met (online) tests en vragenlijsten leren niet alleen jouw werk&shy;nemers waar hun groei&shy;kansen liggen, ook de organisatie leert eruit. Waar staan onze mede&shy;werkers? Wat loopt goed? Wat kunnen we beter doen? Welke keuzes maken we op basis van die resultaten?<br/>Vanuit de jarenlange ervaring in evaluatie&shy;methodieken zoekt Oetang naar een een&shy;malige of recurrente evaluatievorm die past bij jouw bedrijfscultuur, werknemers en uiteraard bij de gewenste output.",

        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
      {
        id: 7,
        title: "Leer&shy;campagnes",
        titleInCode: "leercampagnes",
        label: "leercampagne",
        keywords:
          "learning, leerervaring, design, leren, lxd, leerproduct, levenslang leren, learning experience design, consultancy, product design, productontwikkeling, leercampagne",
        icon: workplaceProductIcons[7],
        path: "/workplace/producten/leercampagnes",
        cardText: "Leerervaringen die samen een groter verhaal vormen.",
        lead: "Rond grotere thema's bewustmakende of mobiliserende leerervaringen opzetten: via verschillende kanalen en met oog op een breed leereffect.",
        heroImg: workplaceProductIcons[7],

        baseline:
          "Leerervaringen <br>vormen samen een <br><span>groter verhaal</span>.",
        baseText:
          "Soms heb je zo van die thema's waar je als organisatie maximaal en in de breedte moet op inzetten. Vaak zijn dat ook duurzame leer&shy;thema's met een mobiliserend, bewust&shy;makend of eman&shy;ciperend karakter. Een multi&shy;channel leer&shy;cam&shy;pagne kan hiervoor een ideale leer&shy;oplossing zijn.<br/>Diverse samenhangende leer&shy;ervaringen worden opgezet voor de medewerkers: van workshops, learning stand-ups, kennisclips in de mailbox, tot infographics aan de muur van elke meeting room ... Oetang bedenkt graag met het L&D-team beklijvende en <em>high impact</em> leercampagnes.",
        linkedCases: {
          title: "Standaardkop <span>voor blokje items bij deze service</span>",
          cases: ["op-til", "passiebad", "erfgoedklasbakken"],
        },
        footerCta: "Hier komt een CTA <span>gericht op het leerproduct</span>",
      },
    ],
  },
  cases: {
    linkedCaseTitles: [],
    portfolio: [
      {
        id: 0,
        title: "Cirkelhelden",
        titleInCode: "cirkelhelden",
        sector: ["edu"],
        partner: "Erfgoedbib Antwerpen",
        website: "https://cirkelhelden.be/",
        tileBaseline:
          "Workshop en digitale tool rond genietend lezen en creatief schrijven",
        tileImg: caseTiles.cirkelheldenTile,
        keywords: "",
        productgroup: [3, 0, 5],
        servicegroup: [3, 4, 5],
        date: "01/02/2022"
      },
      {
        id: 1,
        title: "ABC Muco",
        titleInCode: "abc-muco",
        sector: ["edu"],
        partner: "Muco vzw",
        website: "https://abc-muco.org/",
        tileBaseline: "Bewustmakend en verhalend spel over muco",
        tileImg: caseTiles.mucoTile,
        keywords: "",
        productgroup: [0, 1],
        servicegroup: [1, 2, 3, 4],
        date: "01/11/2021"
      },
      {
        id: 2,
        title: "123 Archeologie",
        titleInCode: "123-archeologie",
        sector: ["edu"],
        partner: "Raakvlak Brugge",
        website: "https://123archeologie.be/",
        tileBaseline: "Uitdagende multimediale challenges over archeologie",
        tileImg: caseTiles.archeologieRaakvlakTile,
        keywords: "",
        productgroup: [0, 6, 4],
        servicegroup: [1, 2, 3, 4],
        date: "01/10/2021"
      },
      {
        id: 3,
        title: "Zin in leren! Zin in leven! (ZILL)",
        titleInCode: "kov-zill",
        sector: ["edu"],
        partner: "Katholiek Onderwijs Vlaanderen",
        website: "https://zill.katholiekonderwijs.vlaanderen/#!/",
        tileBaseline: "Ondersteunde tools voor leerplanimplementatie",
        tileImg: caseTiles.kovZillTile,
        keywords: "",
        productgroup: [5],
        servicegroup: [1, 2, 3, 4],
        date: "01/09/2018"
      },
      {
        id: 4,
        title: "Groeitjes uit Kazou",
        titleInCode: "groeitjes-uit-kazou",
        sector: ["edu"],
        partner: "Kazou",
        website: "https://www.kazou.be/",
        tileBaseline: "Toolkit over persoonlijke groei",
        tileImg: caseTiles.kazouTile,
        keywords: "",
        productgroup: [2],
        servicegroup: [1, 2, 3, 4, 5],
        date: "01/06/2018"
      },
      {
        id: 5,
        title: "Kunstparcours",
        titleInCode: "kunstparcours",
        sector: ["edu"],
        partner: "Concertgebouw Brugge",
        website: "https://www.concertgebouw.be/",
        tileBaseline: "Auditief en visueel belevingsparcours voor jongeren",
        tileImg: caseTiles.concertgebouwbruggeTile,
        keywords: "",
        productgroup: [3],
        servicegroup: [3, 4],
        date: "01/09/2021"
      },
      {
        id: 6,
        title: "Passiebad en -tools",
        titleInCode: "passiebad",
        sector: ["workplace"],
        partner: "Caritas West-Vlaanderen",
        website:
          "https://vormingen.caritaswest.be/vorming/passiebad-leidinggevenden",
        tileBaseline:
          "Opleidingstraject en toolbox voor passievolle leidinggevenden in de zorgsector",
        tileImg: caseTiles.passieTile,
        keywords: "",
        productgroup: [3, 2],
        servicegroup: [1, 2, 3, 4, 5],
        date: "01/06/2021"
      },
      {
        id: 7,
        title: "Texture verjaardagsspel",
        titleInCode: "texture",
        sector: ["edu"],
        partner: "Texture Kortrijk",
        website:
          "https://www.texturekortrijk.be/content/verjaardagsspel-in-texture",
        tileBaseline: "Meeslepend museumspel met spannende verhaallijn",
        tileImg: caseTiles.textureTile,
        keywords: "",
        productgroup: [3],
        servicegroup: [1, 2, 3, 4, 5],
        date: "01/09/2021"
      },
      {
        id: 8,
        title: "Bovenlokale toolkit",
        titleInCode: "op-til",
        sector: ["workplace"],
        partner: "Cultuur OP/TIL",
        website: "https://toolkitoptil.be/pdf/ALL__steunpuntbovenlokaal.pdf",
        tileBaseline: "Faciliterende toolkit voor bovenlokale samenwerkingen",
        tileImg: caseTiles.optilTile,
        keywords: "",
        productgroup: [5],
        servicegroup: [1, 2, 3, 4, 5],
        date: "01/09/2019"
      },
      {
        id: 9,
        title: "Erfgoedklasbakken",
        titleInCode: "erfgoedklasbakken",
        sector: ["edu"],
        partner: "FARO",
        website: "https://erfgoedklasbak.erfgoeddag.be/",
        tileBaseline: "Interactief leerpad met webinars voor interimklasbakken",
        tileImg: caseTiles.erfgoedklasbakkenTile,
        keywords: "",
        productgroup: [4, 3],
        servicegroup: [2, 3, 4, 5],
        date: "01/03/2022"
      },
      {
        id: 10,
        title: "Lespakket Geluksdriehoek",
        titleInCode: "geluksdriehoek",
        sector: ["edu"],
        partner: "Gezond Leven Vlaanderen",
        website:
          "https://www.gezondleven.be/settings/gezonde-school/mentaal-welbevinden-op-school/gelukindeklas/lespakket",
        tileBaseline:
          "Lespakket voor kinderen en jongeren over mentaal welbevinden",
        tileImg: caseTiles.gezondLevenPakketTile,
        keywords: "",
        productgroup: [0],
        servicegroup: [2, 3, 4],
        date: "01/12/2021"
      },
      {
        id: 11,
        title: "Wel in je vel-spel",
        titleInCode: "wel-in-je-vel",
        sector: ["edu"],
        partner: "Gezond Leven Vlaanderen",
        website:
          "https://www.gezondleven.be/materialen/wel-in-je-vel-spel#:~:text=Het%20wel%20in%20je%20vel,welbevinden%2C%20of%20kortweg%3A%20geluk.",
        tileBaseline: "Kaartspel dat doet reflecteren over mentaal welbevinden",
        tileImg: caseTiles.gezondLevenSpelTile,
        keywords: "",
        productgroup: [1],
        servicegroup: [3, 4],
        date: "01/02/2022"
      },
      {
        id: 12,
        title: "Toolkit Happy Snacks",
        titleInCode: "happy-snacks",
        sector: ["edu"],
        partner: "Gezond Leven Vlaanderen",
        website:
          "https://www.gezondleven.be/settings/gezonde-school/mentaal-welbevinden-op-school/gelukindeklas/aan-de-slag",
        tileBaseline:
          "2 Toolkits boordevol werkvormen over mentaal welbevinden",
        tileImg: caseTiles.gezondLevenToolkitTile,
        keywords: "",
        productgroup: [2],
        servicegroup: [2, 3, 4],
        date: "01/10/2021"
      },
      {
        id: 13,
        title: "Billenkarrenspel",
        titleInCode: "billenkarrenspel",
        sector: ["edu"],
        partner: "Kusterfgoed",
        website: "https://kusterfgoed.be/billenkarrenroute/",
        tileBaseline:
          "Interactief gezinsspel langs de kustlijn van 5 badsteden",
        tileImg: caseTiles.billenkarrenspelTile,
        keywords: "",
        productgroup: [1],
        servicegroup: [1, 3, 4],
        date: "01/07/2020"
      },
      {
        id: 14,
        title: "Grammatica-animaties",
        titleInCode: "grammatica-animaties",
        sector: ["edu"],
        partner: "Pelckmans Uitgevers",
        website: "https://info.pelckmansportaal.be/online-leren/",
        tileBaseline: "Grammatica toegankelijk maken via instructievideo's",
        tileImg: caseTiles.grammaticaAnimatiesTile,
        keywords: "",
        productgroup: [4],
        servicegroup: [3, 4],
        date: "01/09/2021"
      },
      {
        id: 15,
        title: "Syntheseanimaties",
        titleInCode: "synthese-animaties",
        sector: ["edu"],
        partner: "Pelckmans Uitgevers",
        website: "https://info.pelckmansportaal.be/online-leren/",
        tileBaseline: "Hoofdstukken samengevat in leervideo's",
        tileImg: caseTiles.syntheseAnimatiesTile,
        keywords: "",
        productgroup: [4],
        servicegroup: [3, 4],
        date: "01/09/2021"
      },
      {
        id: 16,
        title: "EDO",
        titleInCode: "edo",
        sector: ["edu"],
        partner: "Artevelde HS en UA",
        website: "www.edoschool.be",
        tileBaseline:
          "Valorisatiewebsite rond educatie voor duurzame ontwikkeling",
        tileImg: caseTiles.edoTile,
        keywords: "",
        productgroup: [4],
        servicegroup: [1, 2, 3],
        date: "01/03/2022"
      },
      {
        id: 17,
        title: "conceptkaarten",
        titleInCode: "conceptkaarten",
        sector: ["edu"],
        partner: "Oetang",
        website: "https://trainingen.oetang.be/filter/muzische-vorming/",
        tileBaseline:
          "Inspiratie- en ontwerpset voor kunsteducatieve activiteiten",
        tileImg: caseTiles.conceptkaartenTile,
        keywords: "",
        productgroup: [2],
        servicegroup: [3, 4, 5],
        date: "01/12/2017"
      },
      {
        id: 18,
        title: "heldenflix",
        titleInCode: "heldenflix",
        sector: ["edu"],
        partner: "Erfgoedbib Antwerpen",
        website: "https://www.heldenflix.be/",
        tileBaseline: "Workshop en tool over tijdloze ridderverhalen",
        tileImg: caseTiles.heldenflixTile,
        keywords: "",
        productgroup: [3, 0, 5],
        servicegroup: [1, 3, 4, 5],
        date: "01/10/2021"
      },
      {
        id: 19,
        title: "breingravers",
        titleInCode: "breingravers",
        sector: ["edu"],
        partner: "Arteveldehogeschool",
        website: "https://wiskundigbreingraven.be/",
        tileBaseline: "Didactisch leerpadconcept over wiskundig breingraven",
        tileImg: caseTiles.breingravenTile,
        keywords: "",
        productgroup: [4],
        servicegroup: [3],
        date: "01/09/2021"
      },
      {
        id: 20,
        title: "fonemi",
        titleInCode: "fonemi",
        sector: ["edu"],
        partner: "VIVES Hogeschool",
        website: "https://fonemi.be/",
        tileBaseline:
          "Didactisch conceptadvies voor Fonemi taal-app voor kleuters",
        tileImg: caseTiles.fonemiTile,
        keywords: "",
        productgroup: [5],
        servicegroup: [2],
        date: "01/12/2021"
      },
      {
        id: 21,
        title: "Teletijdvitrine",
        titleInCode: "teletijdvitrine",
        sector: ["edu"],
        partner: "Musea Brugge",
        website: "https://museabruggeindeklas.be/teletijdvitrine/",
        tileBaseline: "Verrassende microlessen rond erfgoed en STEM",
        tileImg: caseTiles.teletijdvitrineTile,
        keywords: "",
        productgroup: [0, 5],
        servicegroup: [3, 4],
        date: "01/06/2022"
      },
      {
        id: 22,
        title: "Muzische triptieken",
        titleInCode: "muzische-triptieken",
        sector: ["edu"],
        partner: "Musea Brugge",
        website: "https://museabruggeindeklas.be/muzischetriptieken/",
        tileBaseline: "Kunst leren kijken, digitaal ondersteund",
        tileImg: caseTiles.muzischeTriptiekenTile,
        keywords: "",
        productgroup: [0, 4, 6],
        servicegroup: [3, 4],
        date: "01/06/2022"
      },
      {
        id: 23,
        title: "Curatoren gezocht!",
        titleInCode: "curatoren-gezocht",
        sector: ["edu"],
        partner: "Musea Brugge",
        website: "https://www.museabrugge.be/koop-tickets?offers=NDQ3OA%3D%3D",
        tileBaseline: "Speelse kunstbeleving voor curatoren in spé",
        tileImg: caseTiles.curatorenGezochtTile,
        keywords: "",
        productgroup: [3],
        servicegroup: [3, 4, 5],
        date: "01/08/2022"
      },
      {
        id: 24,
        title: "Welkom in Ooitkamp",
        titleInCode: "welkom-in-ooitkamp",
        sector: ["edu"],
        partner: "Musea Brugge",
        website: "https://www.museabrugge.be/koop-tickets?offers=NDQ3OQ%3D%3D",
        tileBaseline:
          "Een spannend coöperatief bordspel tussen heden en verleden",
        tileImg: caseTiles.welkomInOoitkampTile,
        keywords: "",
        productgroup: [1, 3],
        servicegroup: [3, 4, 5],
        date: "01/08/2022"
      },
      {
        id: 25,
        title: "Stanlie",
        titleInCode: "stanlie",
        sector: ["edu"],
        partner: "Provincie Oost-Vlaanderen",
        website: "https://www.stanlie.be/",
        tileBaseline: "Spannende storytelling over erfgoedzorg",
        tileImg: caseTiles.stanlieTile,
        keywords: "",
        productgroup: [0, 4],
        servicegroup: [1, 3, 4],
        date: "01/09/2022"
      },
      {
        id: 26,
        title: "Erfgoednachtspel",
        titleInCode: "erfgoednachtspel",
        sector: ["edu"],
        partner: "Erfgoedcellen WVL",
        website: "https://erfgoedspel.be/",
        tileBaseline: "Generieke en leerrijke nachtspellen met DIY-toolkit",
        tileImg: caseTiles.erfgoednachtspelTile,
        keywords: "",
        productgroup: [1],
        servicegroup: [1, 2, 3, 4, 5],
        date: "01/04/2020"
      },
      {
        id: 27,
        title: "Oog in oog met de dood",
        titleInCode: "oog-in-oog-met-de-dood",
        sector: ["edu"],
        partner: "Musea Brugge",
        website:
          "https://www.museabrugge.be/kalender/tentoonstellingen/oog-in-oog-met-de-dood",
        tileBaseline: "Coaching en concept voor interactieve rondleiding",
        tileImg: caseTiles.oogInOogTile,
        keywords: "",
        productgroup: [3],
        servicegroup: [1, 2, 3, 5],
        date: "01/11/2022"
      },
      {
        id: 28,
        title: "Musico Tour",
        titleInCode: "musico-tour",
        sector: ["edu"],
        partner: "Muco vzw",
        website: "https://abc-muco.org/#/nl/10-13/musico-tour",
        tileBaseline: "Een bewustmakend bord- en teamspel over muco",
        tileImg: caseTiles.musicoTourTile,
        keywords: "",
        productgroup: [1, 0],
        servicegroup: [3, 4],
        date: "01/12/2022"
      },
      {
        id: 29,
        title: "Zeewind in de klas",
        titleInCode: "zeewind-in-de-klas",
        sector: ["edu"],
        partner: "Horizon Educatief",
        website: "https://zeewindindeklas.be/",
        tileBaseline: "Interactieve digitale doe-lessen over windenergie",
        tileImg: caseTiles.zeewindInDeKlasTile,
        keywords: "",
        productgroup: [4, 0, 6],
        servicegroup: [3, 4],
        date: "01/12/2022"
      },
      {
        id: 30,
        title: "Geronimo Stilton leeschallenge",
        titleInCode: "geronimo-stilton-leeschallenge",
        sector: ["edu"],
        partner: "Pelckmans Uitgevers",
        website: "https://leeschallenge.geronimostiltonboeken.be/",
        tileBaseline: "Motiverende klastool rond leesbevordering",
        tileImg: caseTiles.geronimoLeeschallengeTile,
        keywords: "",
        productgroup: [5],
        servicegroup: [3],
        date: "01/10/2022"
      },
      {
        id: 31,
        title: "Tabel van Mendeljev",
        titleInCode: "tabel-van-mendeljev",
        sector: ["edu"],
        partner: "Pelckmans Uitgevers",
        website: "",
        tileBaseline: "Interactieve leertool voor de chemische elementen",
        tileImg: caseTiles.tabelMendeljevTile,
        keywords: "",
        productgroup: [5],
        servicegroup: [3],
        date: "01/09/2021"
      },
      {
        id: 32,
        title: "Erfgoed Science Lab",
        titleInCode: "efgoed-science-lab",
        sector: ["edu"],
        partner: "Erfpunt",
        website: "",
        tileBaseline: "Didactische coaching en advies voor wetenschapsateliers",
        tileImg: caseTiles.erfgoedScienceLabTile,
        keywords: "",
        productgroup: [3],
        servicegroup: [2, 3, 5],
        date: "01/12/2022"
      },
      {
        id: 33,
        title: "Beleving Pardoes",
        titleInCode: "beleving-pardoes",
        sector: ["edu"],
        partner: "Pardoes",
        website: "",
        tileBaseline: "Leer- en belevingsconcept kinderboekhandel",
        tileImg: caseTiles.pardoesLXDTile,
        keywords: "",
        productgroup: [7],
        servicegroup: [1, 2, 3, 4, 5],
        date: "06/12/2022"
      },
      {
        id: 34,
        title: "Tijdreizigers",
        titleInCode: "tijdreizigers",
        sector: ["edu"],
        partner: "Provincie West-Vlaanderen",
        website: "https://detijdreizigers.be/",
        tileBaseline: "Waar erfgoed en Steam kruisen",
        tileImg: caseTiles.tijdreizigersTile,
        keywords: "",
        productgroup: [0],
        servicegroup: [3],
        date: "01/09/2020"
      },
      {
        id: 35,
        title: "Re:flex",
        titleInCode: "reflex",
        sector: ["edu"],
        partner: "Universiteit Gent",
        website: "https://www.ugent.be/",
        tileBaseline: "6 guided tours over etnische diversiteit",
        tileImg: caseTiles.reflexTile,
        keywords: "",
        productgroup: [4],
        servicegroup: [2, 3],
        date: "01/02/2023"
      },
      {
        id: 36,
        title: "Mediascanners",
        titleInCode: "mediascanners",
        sector: ["edu"],
        partner: "Erfgoedbib Antwerpen",
        website: "https://consciencebibliotheek.be/nl",
        tileBaseline: "Workshop en tools voor mediascanners",
        tileImg: caseTiles.mediascannersTile,
        keywords: "",
        productgroup: [3, 5],
        servicegroup: [3, 4],
        date: "01/03/2023"
      },
      {
        id: 37,
        title: "Landscape Voices",
        titleInCode: "landscape-voices",
        sector: ["edu"],
        partner: "Province West-Vlaanderen en partners",
        website: "https://www.west-vlaanderen.be/",
        tileBaseline: "Leren en filosoferen in de landschappen van WO I",
        tileImg: caseTiles.landscapevoicesTile,
        keywords: "",
        productgroup: [0],
        servicegroup: [3, 4],
        date: "01/02/2023"
      },
      {
        id: 38,
        title: "Maakteam",
        titleInCode: "maakteam",
        sector: ["edu"],
        partner: "Musea Brugge",
        website: "https://www.museabrugge.be/",
        tileBaseline: "Cocreatie kunstzoektocht door en voor kinderen",
        tileImg: caseTiles.maakTeamTile,
        keywords: "",
        productgroup: [1],
        servicegroup: [1, 3],
        date: "01/07/2023"
      },
      {
        id: 39,
        title: "Draw for Change",
        titleInCode: "draw-for-change",
        sector: ["edu"],
        partner: "Clin d'0eil Films",
        website: "https://www.clindoeilfilms.be/",
        tileBaseline: "Interactive workshop en tools over genderongelijkheid",
        tileImg: caseTiles.drawForChangeTile,
        keywords: "",
        productgroup: [0, 3, 5],
        servicegroup: [2, 3],
        date: "31/08/2023"
      },
      {
        id: 40,
        title: "Heks - Heksenstreek",
        titleInCode: "heks-heksenstreek",
        sector: ["edu"],
        partner: "Historische Huizen Gent",
        website: "https://historischehuizen.stad.gent/nl?gclid=Cj0KCQjwrMKmBhCJARIsAHuEAPS_XRC18GruVlbvi2RLwWDq_d6sVeEYnCuOgXrpfF5TLHHZpdAqt1EaAgU2EALw_wcB",
        tileBaseline: "Een workshop met spelelement over zondebokken",
        tileImg: caseTiles.historischeHuizenTile,
        keywords: "",
        productgroup: [3],
        servicegroup: [1, 3],
        date: "01/09/2023"
      },
      {
        id: 41,
        title: "Bunker - Ghostbunkers",
        titleInCode: "bunker-ghostbunkers",
        sector: ["edu"],
        partner: "Erfgoed Bunkergordel",
        website: "https://erfgoedviersprong.be/erfgoedveld/bunkergordel/",
        tileBaseline: "Een mysterieus coöperatief spel voor jongeren",
        tileImg: caseTiles.bunkerTile,
        keywords: "",
        productgroup: [1],
        servicegroup: [3, 4],
        date: "15/07/2023"
      },
      {
        id: 42,
        title: "Klimax",
        titleInCode: "klimax",
        sector: ["edu"],
        partner: "Omgeving Vlaanderen",
        website: "https://www.omgeving.vlaanderen.be/",
        tileBaseline: "Inspiratietool voor impactvolle klimaateducatie",
        tileImg: caseTiles.klimaxTile,
        keywords: "",
        productgroup: [0, 5],
        servicegroup: [2, 3],
        date: "02/09/2023"
      },
      {
        id: 43,
        title: "Sproetje",
        titleInCode: "sproetje",
        sector: ["edu"],
        partner: "Muco vzw",
        website: "https://www.muco.be/nl/?gad_source=1&gclid=CjwKCAjwoPOwBhAeEiwAJuXRh0F726HnGTlGTHQxhIVPqXmtZs5fdvFNDXqcIVmbK4-GyeNpmkyghBoCDNgQAvD_BwE",
        tileBaseline: "Bewustmakende game voor jonge kinderen",
        tileImg: caseTiles.sproetjeTile,
        keywords: "",
        productgroup: [1],
        servicegroup: [3],
        date: "24/01/2024"
      },
      {
        id: 44,
        title: "Belfortbonzen",
        titleInCode: "belfortbonzen",
        sector: ["edu"],
        partner: "Historische Huizen Gent",
        website: "https://historischehuizen.stad.gent/nl?gclid=Cj0KCQjwrMKmBhCJARIsAHuEAPS_XRC18GruVlbvi2RLwWDq_d6sVeEYnCuOgXrpfF5TLHHZpdAqt1EaAgU2EALw_wcB",
        tileBaseline: "Meeslepende stadsactiviteit vol historisch inzicht",
        tileImg: caseTiles.belfortTile,
        keywords: "",
        productgroup: [0, 3],
        servicegroup: [1, 3],
        date: "24/01/2024"
      },
      {
        id: 45,
        title: "Echoes of War",
        titleInCode: "echoes-of-war",
        sector: ["edu"],
        partner: "Passchendaele Museum",
        website: "https://passchendaele.be/",
        tileBaseline: "Een touchscreen die het gezin aan het denken zet",
        tileImg: caseTiles.passchendaeleTile,
        keywords: "",
        productgroup: [5, 7],
        servicegroup: [1, 2, 3],
        date: "24/01/2024"
      },
      {
        id: 46,
        title: "Vonden(f)luisteraars",
        titleInCode: "vondenfluisteraars",
        sector: ["edu"],
        partner: "Raakvlak Brugge",
        website: "https://123archeologie.be/",
        tileBaseline: "Archeologische vondsten komen helemaal tot leven",
        tileImg: caseTiles.archeoTile,
        keywords: "",
        productgroup: [0],
        servicegroup: [3],
        date: "01/09/2023"
      },
      {
        id: 47,
        title: "Sint-Janshospitaal",
        titleInCode: "sint-Janshospitaal",
        sector: ["edu"],
        partner: "Musea Brugge",
        website: "https://www.museabrugge.be/",
        tileBaseline: "Conceptadvies voor belevingspunten in Sint-Janshospitaal",
        tileImg: caseTiles.sintjansTile,
        keywords: "",
        productgroup: [5, 7],
        servicegroup: [1, 2],
        date: "01/10/2023"
      },
    ],
  },
  vacancies: {
    open: [
      {
        id: 0,
        jobtitle: "Learning Designer",
        jobtitleSmall: "Allround concept- en productontwikkelaar",
      },
    ],
    closed: [],
  },
};

function reducer(store = initialState, action) {
  switch (action.type) {
    case "FETCH_PAGE_INFO":
      return {
        ...store,
      };
    case "CHANGE_SECTOR":
      return {
        ...store,
        activeSector: action.payload.newSector,
      };
    case "TOGGLE_MENU":
      return {
        ...store,
        isMenuOpen: !store.isMenuOpen,
      };
    case "CLOSE_MENU":
      return {
        ...store,
        isMenuOpen: false,
      };
    case "CHANGE_SERVICE":
      return {
        ...store,
        services: {
          ...store.services,
          activeService: action.payload.newService,
        },
      };
    case "CHANGE_PRODUCT":
      return {
        ...store,
        products: {
          ...store.products,
          activeProduct: action.payload.newProduct,
        },
      };

    case "FETCH_SERVICE_CASE_TITLES":
      return {
        ...store,
        cases: {
          ...store.cases,
          linkedCaseTitles: action.payload.linkedCaseTitles,
        },
      };
    case "FETCH_PRODUCT_CASE_TITLES":
      return {
        ...store,
        cases: {
          ...store.cases,
          linkedCaseTitles: action.payload.linkedCaseTitles,
        },
      };
    default:
      return store;
  }
}

export default reducer;
