import React, { Component } from "react";
import PropTypes from "prop-types";

class Motto extends Component {
  render() {
    const { source } = this.props;
    return (
      <section className="motto">
        <h2
          className="motto__title"
          dangerouslySetInnerHTML={{ __html: source }}
        ></h2>
      </section>
    );
  }
}

Motto.propTypes = {
  source: PropTypes.string,
};

export default Motto;
