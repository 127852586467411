import React, { Component } from "react";
import PropTypes from "prop-types";
import Obfuscate from "react-obfuscate";

class CtaBanner extends Component {
  render() {
    const { title } = this.props;
    return (
      <section className="cta-banner">
        <h3
          className="cta-banner__title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h3>
        <Obfuscate
          className="cta-banner__btn btn-green"
          email="post@oetang.be"
          obfuscateChildren={false}
        >
          Contacteer ons<i className="icon-contact"></i>
        </Obfuscate>
      </section>
    );
  }
}

CtaBanner.propTypes = {
  title: PropTypes.string,
};

export default CtaBanner;
