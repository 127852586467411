// Product icons
import i_Oetang__edu_pakketten from "./products/i_Oetang__edu_pakketten.svg";
import i_Oetang__edu_etools from "./products/i_Oetang__edu_etools.svg";
import i_Oetang__edu_instructievideos from "./products/i_Oetang__edu_instructievideos.svg";
import i_Oetang__edu_leerpaden from "./products/i_Oetang__edu_leerpaden.svg";
import i_Oetang__edu_leervisuals from "./products/i_Oetang__edu_leervisuals.svg";
import i_Oetang__edu_spellen from "./products/i_Oetang__edu_spellen.svg";
import i_Oetang__edu_toolkits from "./products/i_Oetang__edu_toolkits.svg";
import i_Oetang__edu_workshops from "./products/i_Oetang__edu_workshops.svg";
import i_Oetang__workplace_businessGames from "./products/i_Oetang__pro_games-toolkits.svg";
import i_Oetang__workplace_instructievideos from "./products/i_Oetang__pro_instructievidoes.svg";
import i_Oetang__workplace_leercampagnes from "./products/i_Oetang__pro_leercampagnes.svg";
import i_Oetang__workplace_leerpaden from "./products/i_Oetang__pro_leerpaden.svg";
import i_Oetang__workplace_leervisuals from "./products/i_Oetang__pro_leervisuals.svg";
import i_Oetang__workplace_microlearnings from "./products/i_Oetang__pro_micro.svg";
import i_Oetang__workplace_opleidingstrajecten from "./products/i_Oetang__pro_opleidingstrajecten.svg";
import i_Oetang__workplace_testsQuizzen from "./products/i_Oetang__pro_test-quiz.svg";

export const eduProductIcons = [
  i_Oetang__edu_pakketten,
  i_Oetang__edu_spellen,
  i_Oetang__edu_toolkits,
  i_Oetang__edu_workshops,
  i_Oetang__edu_leerpaden,
  i_Oetang__edu_etools,
  i_Oetang__edu_instructievideos,
  i_Oetang__edu_leervisuals,
];
export const workplaceProductIcons = [
  i_Oetang__workplace_businessGames,
  i_Oetang__workplace_microlearnings,
  i_Oetang__workplace_opleidingstrajecten,
  i_Oetang__workplace_leerpaden,
  i_Oetang__workplace_instructievideos,
  i_Oetang__workplace_leervisuals,
  i_Oetang__workplace_testsQuizzen,
  i_Oetang__workplace_leercampagnes,
];
