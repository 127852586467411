import React, { Component } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HomeHero from "../components/HomeHero";
import Motto from "../components/Motto";
import OverOnsQuickNav from "../components/OverOnsQuickNav";
import Banner from "../assets/imgs/oetang-about-us.png";
import Photo from "../assets/imgs/algemene_beelden/oetang_home-jumper.png";
import LinkedCases from "../components/LinkedCases";
import { serviceShapesWithoutWhite } from "../assets/shapes/services/shapeImports";
import BtnCircle from "../components/btns/BtnCircle";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
class Home extends Component {
  render() {
    return (
      <div id="home">
        <div className="shapes">
          <img
            src={serviceShapesWithoutWhite.research}
            className="serviceShape shapeResearch"
            alt=""
          />
        </div>
        <HomeHero></HomeHero>
        <Motto
          source="Oetang is een <span>Learning Experience Design Studio</span>. We
        maken slimme leerproducten die van alles in beweging zetten."
        ></Motto>
        <section className="sector-choice">
          <div className="sector-choice__cards">
            <Link to="/edu" className="sector-choice-card">
              <div className="sector-choice-card__body">
                <h3>For education</h3>
                <p>
                  Oetang werkt in en voor educatieve contexten: het onderwijs,
                  het brede cultuur- en erfgoedveld, de 'grote educaties' zoals
                  gezondheid, milieu, duurzaamheid ...
                </p>
              </div>
              <div className="sector-choice-card__footer">
                <div>
                  <BtnCircle></BtnCircle>
                  <span>Lees meer</span>
                </div>
              </div>
            </Link>
            <Link to="/workplace" className="sector-choice-card">
              <div className="sector-choice-card__body">
                <h3>For workplace learning</h3>
                <p>
                  Oetang is graag partner van Learning & Development diensten
                  die mikken op <i>lifelong learning</i> en{" "}
                  <i>workplace learning</i> bij hun medewerkers.
                </p>
              </div>
              <div className="sector-choice-card__footer">
                <div>
                  <BtnCircle></BtnCircle>
                  <span>Lees meer</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="sector-choice__photo">
            <img alt="Sector choice" src={Photo} />
          </div>
        </section>
        <LinkedCases
          title="Recente <span>leerproducten</span><br>van onze studio"
          caseTitles={["passiebad", "happy-snacks", "123-archeologie"]}
        ></LinkedCases>
        <OverOnsQuickNav></OverOnsQuickNav>
        <Footer
          page="home"
          cta="Dus, wanneer <span>beginnen we?</span>"
        ></Footer>
      </div>
    );
  }
}

export default Home;
