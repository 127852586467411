import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ProductHero extends Component {
  render() {
    const { activeSector, activeProduct } = this.props;
    return (
      <section className={`product-hero product-hero--${activeSector}`}>
        <ul className="product-hero-breadcrumb">
          <li>
            <Link to={`/${activeSector === "edu" ? "edu" : "workplace"}`}>
              {activeSector === "edu" ? "Educatie" : "Workplace"}
            </Link>
          </li>
          <li dangerouslySetInnerHTML={{ __html: activeProduct.title }}></li>
        </ul>
        <div className="product-hero-pagelead">
          <div className="product-hero-pagelead__info">
            <h1
              className="product-hero-pagelead__info-title"
              dangerouslySetInnerHTML={{
                __html: this.props.customTitle
                  ? this.props.customTitle
                  : activeProduct.title,
              }}
            ></h1>
            <p
              className="product-hero-pagelead__info-lead"
              dangerouslySetInnerHTML={{ __html: activeProduct.lead }}
            ></p>
          </div>
          <div className="product-hero-pagelead__aside">
            <img alt="" src={activeProduct.heroImg}></img>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(ProductHero);
