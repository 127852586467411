import React, { Component } from "react";
import Obfuscate from "react-obfuscate";
import logo from "../assets/logos/logo_oetang__solo_greenprim@2x.png";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div className={`footer footer--${this.props.page}`}>
        <div className="footer-cta">
          <div className="footer-cta__wrapper">
            <h3
              className="footer-cta__title"
              // dangerouslySetInnerHTML={{ __html: `${this.props.cta}` }}
            >
              Samen een <span>leerproduct</span> maken?
            </h3>
            <Obfuscate
              className="footer-cta__btn btn-green"
              email="post@oetang.be"
              obfuscateChildren={false}
            >
              Contacteer ons<i className="icon-contact"></i>
            </Obfuscate>
          </div>
          <img src={logo} alt="Logo van Oetang Learning Designers" />
        </div>
        <div className="footer-info">
          <div className="footer-info__contact">
            <Obfuscate className="footer-info__email" email="post@oetang.be" />
            <Obfuscate className="footer-info__tel" tel="09 311 20 00" />
          </div>
          <div className="footer-info__address">
            <p className="footer-info__address-street">
              Platteberg 8
            </p>
            <p className="footer-info__address-city">9000 Gent</p>
            <a
              href="https://goo.gl/maps/Cyx65TpKCPjPbJyx7"
              target="_blank"
              rel="noreferrer"
              className="footer-info__address-route"
            >
              Wegbeschrijving
            </a>
          </div>
          <div className="footer-info__social">
            <ul className="footer-info__social">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/OetangLearningDesigners/"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/oetanglearningdesigners/"
                >
                  Instagram
                </a>
              </li>
              {/*<li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/OetangLxD"
                >
                  Twitter
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="footer-credit">
          <div className="footer-credit__policy">
            <Link to="/privacy">
              <span>Privacy policy</span>
              <span> - </span>
              <span>Cookie policy</span>
            </Link>
          </div>

          <p className="footer-credit__bank">
            <span>BTW BE 0837.751.980</span>
            <span> - </span>
            <span>IBAN BE95 0016 4637 7158</span>
          </p>
        </div>
        <div class="footer-cross-reference"><small>Oetang BV bestaat uit twee units: <a target="_blank" rel="noreferrer" href="https://oetang.be/">Oetang Learning Design Studio<i class="icon-external"></i></a> en <a href="https://trainingen.oetang.be">Oetang Trainingen en Trajecten</a></small></div>
      </div>
    );
  }
}

Footer.propTypes = {
  page: PropTypes.string,
  cta: PropTypes.string,
};

export default Footer;
