import React, { useEffect, useState } from "react";
// import { useCookies, withCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import ReactGA from "react-ga";

const Cookie = (props) => {
  const [consentCookie, setConsentCookie] = useState("null");

  const { pathname } = useLocation();

  useEffect(() => {
    if (Cookies.get("consent")) {
      setConsentCookie(Cookies.get("consent"));
    }
    if (Cookies.get("consent") === "true") {
      initializationGA();
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else {
      disableDefaultTracker();
    }
  }, [consentCookie, pathname]);

  function cookieAccept(e) {
    e.preventDefault();
    Cookies.set("consent", true, { expires: 365 }); // in browser
    setConsentCookie("true"); // in component state
    initializationGA();
  }
  function cookieDecline(e) {
    e.preventDefault();
    Cookies.set("consent", false, { expires: 365 }); // in browser
    setConsentCookie("false"); // in component state
    disableDefaultTracker();
  }

  function initializationGA() {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
    });
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(window.location.href);
    }
  }

  const disableDefaultTracker = () => {
    // Remove the default tracker.
    if (window.ga) window.ga("remove");
    // Remove the default cookies
    // _ga is used to distinguish users.
    Cookies.remove("_ga", { path: "/", domain: document.domain });
    // _gid is used to distinguish users.
    Cookies.remove("_gid", { path: "/", domain: document.domain });
    // _gat is used to throttle request rate.
    Cookies.remove("_gat", { path: "/", domain: document.domain });
  };

  return (
    <div
      className="cookie"
      style={{
        display: consentCookie !== "null" ? "none" : "block",
      }}
    >
      <h3>Even je aandacht...</h3>
      <p>
        Deze website gebruikt cookies voor doelen die je kan nalezen in onze{" "}
        <Link to="/privacy">privacy en cookie policy</Link>.
      </p>
      <p></p>
      <button onClick={(e) => cookieAccept(e)} className="btn-green">
        Begrepen
      </button>
      <div className="btn-cookie-decline" onClick={(e) => cookieDecline(e)}>
        Liever niet
      </div>
    </div>
  );
};

export default Cookie;
