import React from "react";

import { HashLink } from "react-router-hash-link";
OverOnsQuickNav.propTypes = {};

function OverOnsQuickNav(props) {
  return (
    <section className="over-ons-quicknav">
      <h2 className="over-ons-quicknav__title">We zijn Learning Designers</h2>
      <h3 className="over-ons-quicknav__lead">
        Oetang is een Learning Design Studio.<br></br> We zijn{" "}
        <span>gepassioneerd</span> door alles wat met leren te maken heeft en
        zijn experts in{" "}
        <span style={{ whiteSpace: "nowrap" }}>Learning Experience Design</span>
        .
      </h3>
      <HashLink
        to="/over-ons#over-ons"
        className="over-ons-quicknav__btn btn-green btn-green--right"
      >
        Meer over ons<i className="icon-arrow-right"></i>
      </HashLink>
    </section>
  );
}

export default OverOnsQuickNav;
