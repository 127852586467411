import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { changeService } from "../../redux/actions/actions";
import EduOnderzoek from "./services/EduOnderzoek";
import EduStrategie from "./services/EduStrategie";
import EduProductDesign from "./services/EduProductDesign";
import EduContentCreatie from "./services/EduContentCreatie";
import EduBegeleiding from "./services/EduBegeleiding";
import Header from "../../components/Header";
import Helmet from "react-helmet";

function EduService(props) {
  const dispatch = useDispatch();
  const currentUrl = useLocation().pathname;
  const serviceIndex = currentUrl.indexOf("diensten") + 9;
  useLocation();

  useEffect(() => {
    if (currentUrl.slice(serviceIndex, currentUrl.length).slice(-1) === "/") {
      dispatch(
        changeService(
          currentUrl.slice(serviceIndex, currentUrl.length).split("/")[0]
        )
      );
    } else {
      dispatch(
        changeService(currentUrl.slice(serviceIndex, currentUrl.length))
      );
    }
  }, []);

  const { services } = props;
  return (
    <div>
      <Header headerBG="beige"></Header>
      <Helmet titleTemplate="Oetang | EDU - %s"></Helmet>
      <Routes>
        <Route
          exact
          path={`/${services[0].titleInCode}`}
          element={<EduOnderzoek />}
        />
        <Route
          exact
          path={`/${services[1].titleInCode}`}
          element={<EduStrategie />}
        />
        <Route
          exact
          path={`/${services[2].titleInCode}`}
          element={<EduProductDesign />}
        />
        <Route
          exact
          path={`/${services[3].titleInCode}`}
          element={<EduContentCreatie />}
        />
        <Route
          exact
          path={`/${services[4].titleInCode}`}
          element={<EduBegeleiding />}
        />
        <Route exact path="/" element={<EduOnderzoek />} />
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: state.services.edu,
});

export default connect(mapStateToProps, null)(EduService);
