import React, { Component } from "react";
import { connect } from "react-redux";

class ServiceProces extends Component {
  render() {
    const { activeService, activeSector } = this.props;

    return (
      <section
        className={`service-proces service-proces--${activeSector}`}
        id="aanpak"
      >
        <h2 className="service-proces__title">
          {activeService.proces.procesTitle}
        </h2>
        <div className="service-proces__wrapper">
          {/* start card */}
          {activeService.proces.procesCards.map((card, i) => (
            <div key={i} className="service-proces-card">
              <div className="service-proces-card__header">
                <h3 dangerouslySetInnerHTML={{ __html: card.title }}></h3>
              </div>
              <div className="service-proces-card__body">
                <p dangerouslySetInnerHTML={{ __html: card.bodyText }}></p>
              </div>
            </div>
          ))}

          {/* end card */}
        </div>
      </section>
    );
    // (equal height op desktop);
  }
}

const mapStateToProps = (state) => ({
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(ServiceProces);
