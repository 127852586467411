import React, { Component, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { changeProduct } from "../../redux/actions/actions";
import EduEducatievePakketten from "./products/EduEducatievePakketten";
import EduEducatieveSpellen from "./products/EduEducatieveSpellen";
import EduETools from "./products/EduETools";
import EduInstructievideos from "./products/EduInstructievideos";
import EduLeerpaden from "./products/EduLeerpaden";
import EduLeervisuals from "./products/EduLeervisuals";
import EduToolkits from "./products/EduToolkits";
import EduWorkshops from "./products/EduWorkshops";

const EduProduct = (props) => {
  const products = useSelector((state) => state.products.edu);
  const dispatch = useDispatch();
  const currentUrl = useLocation().pathname;
  const productIndex = currentUrl.indexOf("producten") + 10;
  useLocation();

  useEffect(() => {
    if (currentUrl.slice(productIndex, currentUrl.length).slice(-1) === "/") {
      dispatch(
        changeProduct(
          currentUrl.slice(productIndex, currentUrl.length).split("/")[0]
        )
      );
    } else {
      dispatch(
        changeProduct(currentUrl.slice(productIndex, currentUrl.length))
      );
    }
  }, []);

  return (
    <div>
      <Header headerBG={"lightblue"}></Header>
      <Helmet titleTemplate="Oetang | EDU - %s"></Helmet>

      <Routes>
        <Route exact path="/" element={<EduEducatievePakketten />} />
        <Route
          exact
          path={`/${products[0].titleInCode}`}
          element={<EduEducatievePakketten />}
        />
        <Route
          exact
          path={`/${products[1].titleInCode}`}
          element={<EduEducatieveSpellen />}
        />
        <Route
          exact
          path={`/${products[2].titleInCode}`}
          element={<EduToolkits />}
        />
        <Route
          exact
          path={`/${products[3].titleInCode}`}
          element={<EduWorkshops />}
        />
        <Route
          exact
          path={`/${products[4].titleInCode}`}
          element={<EduLeerpaden />}
        />
        <Route
          exact
          path={`/${products[5].titleInCode}`}
          element={<EduETools />}
        />
        <Route
          exact
          path={`/${products[6].titleInCode}`}
          element={<EduInstructievideos />}
        />
        <Route
          exact
          path={`/${products[7].titleInCode}`}
          element={<EduLeervisuals />}
        />
      </Routes>
    </div>
  );
};

export default EduProduct;
