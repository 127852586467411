import React, { useState } from "react";
import { serviceHeroImgs } from "../assets/imgs/services/ServiceImgImports";
Strengths.propTypes = {};

function Strengths(props) {
  // slide.scrollIntoView(0, 0);
  const [activeSlide, setActiveSlide] = useState(0);
  const imgWidth = 536;
  const carousel = {
    strengths: [
      {
        id: 0,
        img: serviceHeroImgs[0],
        isActive: true,
        title: "Eerste troef",
        text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum architecto veritatis beatae amet maiores tenetur error, suscipit vero nemo vitae reiciendis autem molestias aperiam labore quo eius? Numquam, omnis odio.",
      },
      {
        id: 1,
        img: serviceHeroImgs[1],
        isActive: false,
        title: "Tweede troef",
        text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum architecto veritatis beatae amet maiores tenetur error, suscipit vero nemo vitae reiciendis autem molestias aperiam labore quo eius? Numquam, omnis odio.",
      },
      {
        id: 2,
        img: serviceHeroImgs[2],
        isActive: false,
        title: "Derde troef",
        text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum architecto veritatis beatae amet maiores tenetur error, suscipit vero nemo vitae reiciendis autem molestias aperiam labore quo eius? Numquam, omnis odio.",
      },
      {
        id: 3,
        img: serviceHeroImgs[3],
        isActive: false,
        title: "Vierde troef",
        text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum architecto veritatis beatae amet maiores tenetur error, suscipit vero nemo vitae reiciendis autem molestias aperiam labore quo eius? Numquam, omnis odio.",
      },
      {
        id: 4,
        img: serviceHeroImgs[4],
        isActive: false,
        title: "Vijfde troef",
        text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum architecto veritatis beatae amet maiores tenetur error, suscipit vero nemo vitae reiciendis autem molestias aperiam labore quo eius? Numquam, omnis odio.",
      },
    ],
  };

  const scrollTo = (strength) => {
    if (strength < carousel.strengths.length - 2) {
      document.querySelector(".strengths__slider").scrollLeft =
        strength * imgWidth;
    } else {
      document.querySelector(".strengths__slider").scrollLeft =
        strength * 530 - (100 % -imgWidth);
    }

    // .scrollIntoView({
    //   behavior: "smooth",
    //   block: "nearest",
    //   inline: "start",
    // });
  };

  const handleChange = (slide) => {
    setActiveSlide(slide);
    scrollTo(slide);
  };

  const GoBack = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
      scrollTo(activeSlide - 1);
    } else if (activeSlide === 4) {
      setActiveSlide(carousel.strengths.length - 2);
      scrollTo(carousel.strengths.length - 2);
    } else {
      // setActiveSlide(activeSlide);
    }
  };

  const GoFurther = () => {
    if (activeSlide < carousel.strengths.length - 1) {
      setActiveSlide(activeSlide + 1);
      scrollTo(activeSlide + 1);
    } else if (activeSlide === 0) {
      scrollTo(carousel.strengths.length + 2);
      setActiveSlide(carousel.strengths.length + 2);
    } else {
      // setActiveSlide(activeSlide);
    }
  };

  //   useEffect(() => {
  //     const next = (activeSlide + 1) % carousel.strengths.length;
  //     const id = setTimeout(() => handleChange(next), 4000);
  //     // const scrollListener = document.querySelector(".strengths__slider");
  //     // const scrollCheck = setTimeout(
  //     //   scrollListener.addEventListener(
  //     //     "scroll",
  //     //     (event) => {
  //     //       const scrollLeft = scrollListener.scrollLeft / imgWidth;
  //     //       if (
  //     //         imgWidth * carousel.strengths.length - 1 - scrollLeft <
  //     //         imgWidth
  //     //       ) {
  //     //         setActiveSlide(carousel.strengths.length - 1);
  //     //       } else {

  //     //       }
  //     //     },
  //     //     { passive: true }
  //     //   ),
  //     //   3000
  //     // );
  //     return () => clearTimeout(id);
  //   }, [activeSlide]);

  // const inViewObserver = new IntersectionObserver(
  //   (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         // do stuff when in view
  //         entry.target.classList.remove("slider-carousel__item--active");
  //         entry.target.classList.add("slider-carousel__item--active");
  //         setActiveSlide(entry.key);
  //         console.log("In view!!");
  //       } else {
  //         // do stuff when not in view
  //         entry.target.classList.remove("slider-carousel__item--active");
  //         console.log("not in view ...");
  //       }
  //     });
  //   },
  //   { threshold: 0.5 }
  // );

  // const slideElements = document.querySelectorAll(".slider-carousel__item");
  // slideElements.forEach((slideElement) => {
  //   inViewObserver.observe(slideElement);
  // });

  return (
    <div className="strengths strengths-edu-home">
      <h2 className="strengths__title">Onze troeven</h2>
      <div className="strengths__slider">
        {carousel.strengths.map((slide, i) => (
          <img
            key={i}
            src={slide.img}
            onClick={() => handleChange(i)}
            onMouseEnter={() => setActiveSlide(i)}
            id={`slider-carousel__item${i}`}
            className={`slider-carousel__item ${
              i === activeSlide ? "slider-carousel__item--active" : ""
            }`}
          ></img>
        ))}
        <div className="slider-carousel__mock-item"></div>
      </div>
      <div className="strengths__slider-nav">
        <div className="strengths__slider-nav-info">
          <h3
            dangerouslySetInnerHTML={{
              __html: carousel.strengths[activeSlide].title,
            }}
          ></h3>
          <p
            dangerouslySetInnerHTML={{
              __html: carousel.strengths[activeSlide].text,
            }}
          ></p>
        </div>
        <div className="strengths__slider-nav-btns">
          <div
            className={`btn-green-on-green ${
              activeSlide === 0 ? "btn-green-on-green--inactive" : ""
            }`}
            onClick={() => GoBack()}
          >
            <i className="icon-arrow-left"></i>
          </div>
          <div
            className={`btn-green-on-green ${
              activeSlide === carousel.strengths.length - 1
                ? "btn-green-on-green--inactive"
                : ""
            }`}
            onClick={() => GoFurther()}
          >
            <i className="icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Strengths;
