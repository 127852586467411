import React, {useState} from "react";
import emailjs from "emailjs-com";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Helmet from "react-helmet";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        if (name && email && message) {
            const serviceId = "service_i50r63f";
            const templateId = "template_8pljubn";
            const userId = "oZ4Altn4_C7OSkj-0";
            const templateParams = {
                name,
                email,
                message,
            };

            emailjs
                .send(serviceId, templateId, templateParams, userId)
                .then((response) => console.log(response))
                .then((error) => console.log(error));

            setName("");
            setEmail("");
            setMessage("");
            setEmailSent(true);
        } else {
            alert("Gelieve alle velden in te vullen.");
        }
    };

    return (
        <div>
            <Helmet>
                <title>Contact</title>
                <meta
                    name="description"
                    content="Een vraag? Zin om samen te werken met Oetang? Stuur een mailtje of kom even langs bij ons op kantoor. Altijd welkom in de jungle."
                ></meta>
                <meta
                    name="keywords"
                    content="learning, leerervaring, design, leren, lxd, leerproduct, educatie, workplace, business, learning &amp; development, onderwijs, erfgoed, lifelong learning, learning experience design"
                ></meta>
            </Helmet>
            <Header></Header>

            <section className="contact-hero">
                <div className="contact-hero-pagelead">
                    <div className="contact-hero-pagelead__info">
                        <p className="contact-hero-pagelead__info-lead">
                            Een vraag? Een idee?
                        </p>
                        <h1 className="contact-hero-pagelead__info-title">
                            <a href="mailto:post@oetang.be?subject=Contactformulier oetang.be">
                                post@oetang.be
                            </a>
                        </h1>
                        <p className="contact-hero-pagelead__info-lead">
                            Platteberg 8<br></br>
                            9000 Gent, Belgium
                        </p>
                        <p className="contact-hero-pagelead__info-lead">
                            Tel. 09 311 20 00
                        </p>
                        <i className="icon-arrow-down"></i>
                    </div>
                </div>
            </section>

            <section id="contact">
                <div className="contact-wrapper">
                    <div>
                        <h3>
                            Een vraag?<br></br>
                            <span>Zin</span> om samen te<br></br>
                            werken met Oetang?
                        </h3>

                        <p>
                            Stuur een mailtje of kom even langs bij ons op
                            kantoor. Altijd welkom in de jungle.
                        </p>

                        <div className="divider-line"></div>

                        <h3>
                            Op zoek naar een<br></br>
                            <span className="red">onderwijstraining</span>?
                        </h3>

                        <p>
                            Onze trainingen en trajecten voor onderwijsteams
                            vind je{" "}
                            <a
                                href="https://trainingen.oetang.be/"
                                target="_blank"
                            >
                                hier
                            </a>
                            .
                        </p>

                        <a
                            className="btn-red"
                            href="https://trainingen.oetang.be/"
                            target="_blank"
                        >
                            Ontdek aanbod <i className="icon-external"></i>
                        </a>
                    </div>

                    <div>
                        <h2>Stuur een bericht</h2>
                        <div className="contact__form">
                            <div className="contact__form-input">
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Jouw naam"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label htmlFor="name">Jouw naam</label>
                            </div>

                            <div className="contact__form-input">
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="E-mailadres"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label htmlFor="email">E-mailadres</label>
                            </div>

                            <div className="contact__form-input">
                                <textarea
                                    id="text"
                                    placeholder="Jouw bericht"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    rows="5"
                                ></textarea>
                                <label htmlFor="text">Jouw bericht</label>
                            </div>

                            <button onClick={submit} className="btn-green">
                                Verzenden
                            </button>

                            <span className={emailSent ? "visible" : null}>
                                Bedankt voor je bericht, we nemen snel contact
                                op!
                            </span>
                        </div>
                    </div>
                </div>

                <p className="contact-company">
                    Oetang BV<br></br>
                    BTW BE 0837.751.980<br></br>
                    IBAN BE95 0016 4637 7158
                </p>
            </section>

            <Footer></Footer>
        </div>
    );
};

export default Contact;