import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { changeService } from "../redux/actions/actions";

const ServiceQuickNav = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, location.pathname);
  const { eduServices, activeSector, jobServices } = props;

  // const customTitles = eduServices.map((service) => {
  //   if (service.title === "Product design") {
  //     return "Product<br>design";
  //   } else if (service.title === "Content&shy;creatie") {
  //     return "Content-<br>creatie";
  //   } else {
  //     return service.title;
  //   }
  // });

  return (
    <section className="service-quick-nav">
      <h2
        className="service-quick-nav__title"
        dangerouslySetInnerHTML={{
          __html: `Andere <span>learning design</span> diensten`,
        }}
      ></h2>
      <div className="service-quick-nav__wrapper">
        {/* start of quick nav service card */}

        {activeSector === "edu"
          ? eduServices.map((service, i) => (
              <Link
                key={i}
                to={service.path}
                onClick={() => dispatch(changeService(service.titleInCode))}
                className={`service-quick-card service-quick-card--${
                  service.titleInCode
                } ${
                  location.pathname.includes(service.titleInCode) === true
                    ? "service-quick-card--current"
                    : ""
                } service-quick-card--firstlastup`}
              >
                <div className="service-quick-card__icon">
                  <img alt="" src={service.iconOnWhite} />
                </div>
                <div className="service-quick-card__servicetitle">
                  <h3 dangerouslySetInnerHTML={{ __html: service.title }}></h3>
                </div>
              </Link>
            ))
          : jobServices.map((service, i) => (
              <Link
                key={i}
                to={service.path}
                onClick={() => dispatch(changeService(service.titleInCode))}
                className={`service-quick-card service-quick-card--${
                  service.titleInCode
                } ${
                  location.pathname.includes(service.titleInCode) === true
                    ? "service-quick-card--current"
                    : ""
                } service-quick-card--firstlastup`}
              >
                <div className="service-quick-card__icon">
                  <img alt="" src={service.iconOnWhite} />
                </div>
                <div className="service-quick-card__servicetitle">
                  <h3 dangerouslySetInnerHTML={{ __html: service.title }}></h3>
                </div>
              </Link>
            ))}

        {/* end of quick nav service card
         */}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  eduServices: state.services.edu,
  jobServices: state.services.job,
  activeSector: state.activeSector,
});

export default connect(mapStateToProps, null)(ServiceQuickNav);
