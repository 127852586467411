import serviceHeroStrategie from "./service-hero-pagelead--edu-strategie.jpg";
import serviceHeroOnderzoek from "./service-hero-pagelead--edu-onderzoek.jpg";
import serviceHeroProductDesign from "./service-hero-pagelead--edu-product-design.jpg";
import serviceHeroContentCreation from "./service-hero-pagelead--edu-content-creation.jpg";
import serviceHeroBegeleiding from "./service-hero-pagelead--edu-begeleiding.jpg";

export const serviceHeroImgs = [
  serviceHeroOnderzoek,
  serviceHeroStrategie,
  serviceHeroProductDesign,
  serviceHeroContentCreation,
  serviceHeroBegeleiding,
];
