// import { nominalTypeHack } from "prop-types";
import React, { useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import aap from "../assets/logos/logo-oetang_aap-groen-wit.svg";
import { closeMenu, toggleMenu } from "../redux/actions/actions";
import Menu from "./menu/Menu";

import useOutsideClick from "../components/helpers/useOutsideClick";

// const Menu = (props) => {
//   const eduProducts = useSelector((state) => state.products.edu);

const Header = (props) => {
  const isMenuOpen = useSelector((state) => state.isMenuOpen);
  const [clickedSector, setClickedSector] = useState(null);
  const dispatch = useDispatch();
  const vacancies = useSelector((state) => state.vacancies.open.length);
  const pathname = useLocation().pathname;
  //   this.toggleJobMenu = this.toggleJobMenu.bind(this);
  //   this.toggleEduMenu = this.toggleEduMenu.bind(this);
  //   this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  // }
  const toggleEduMenu = () => {
    dispatch(toggleMenu());
    setClickedSector("edu");
  };
  const toggleJobMenu = () => {
    dispatch(toggleMenu());
    setClickedSector("workplace");
  };
  const toggleMobileMenu = (e) => {
    e.preventDefault();
    setClickedSector("mobile");
    dispatch(toggleMenu());
  };

   // load custom hook useOutsideClick
   const ref = useRef();
   useOutsideClick(ref, () => {
     if (isMenuOpen === true) {
       dispatch(closeMenu());
     }
   });

  return (
    <div ref={ref}>
      <div className={`header header--${props.headerBG}`}>
        <div className="logo">
          <Link to={`/`}>
            <img className="logo__aap" src={aap} alt="Oetang" />
          </Link>
          <Link to={`/`}>
            <div className="logo__title" alt="Oetang" />
          </Link>
        </div>

        <div className="header-menu">
          <ul className="header-menu__main-nav">
            <li
              className={`header-menu__main-nav-item header-menu__main-nav-item--sidebar ${
                pathname === "/edu" ? "header-menu__main-nav-item--active" : ""
              }`}
            >
              <a onClick={() => toggleEduMenu()}>Education</a>
            </li>
            <li
              className={`header-menu__main-nav-item header-menu__main-nav-item--sidebar ${
                pathname === "/workplace" ? "header-menu__main-nav-item--active" : ""
              }`}
            >
              <a onClick={() => toggleJobMenu()}>Workplace</a>
            </li>
            <li
              className={`header-menu__main-nav-item ${
                pathname === "/portfolio"
                  ? "header-menu__main-nav-item--active"
                  : ""
              }`}
            >
              <Link to={`/portfolio`}>Portfolio</Link>
            </li>
            <li
              style={{ display: "none" }}
              className={`header-menu__main-nav-item ${
                pathname === "/blog" ? "header-menu__main-nav-item--active" : ""
              }`}
            >
              <Link to={`/blog`}>Blog</Link>
            </li>

            <li
              className={`header-menu__main-nav-item ${
                pathname === "/over-ons"
                  ? "header-menu__main-nav-item--active"
                  : ""
              }`}
            >
              <Link to={`/over-ons`}>Over ons</Link>
            </li>
            {/* <li
              className={`header-menu__main-nav-item ${
                pathname === "/jobs" ? "header-menu__main-nav-item--active" : ""
              }`}
            >
              <Link to={`/jobs`}>Jobs</Link>
              <div className="vacancies-count">
                <span>{vacancies}</span>
              </div>
            </li> */}
            <li
              className={`header-menu__main-nav-item ${
                pathname === "/contact"
                  ? "header-menu__main-nav-item--active"
                  : ""
              }`}
            >
              <Link to={`/contact`}>Contact</Link>
            </li>
            <li className="header-menu__main-nav-item header-menu__main-nav-item--training">
                    <Link
                        to={`https://trainingen.oetang.be/` }
                        target="_blank"
                    >
                        Nascholing
                    </Link>
                </li>
            <li className="header-menu__main-nav-item">
              <label className="hamburger" htmlFor="check">
                <input
                  type="checkbox"
                  id="check"
                  onClick={(e) => toggleMobileMenu(e)}
                />
                <span></span>
                <span></span>
                <span></span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <Menu
        isMenuOpen={isMenuOpen}
        // onMenuToggle={this.toggleMenu}
        clickedSector={clickedSector}
      ></Menu>
    </div>
  );
};

export default Header;
