import { createStore, compose, applyMiddleware } from "redux";
import reducer from "./reducers/reducers";
import thunk from "redux-thunk";
// import { configureStore } from "@reduxjs/toolkit";

const store = createStore(
  reducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
