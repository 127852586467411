import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import EduHome from "./EduHome";
import EduService from "./EduService";
import EduProduct from "./EduProduct";
class Edu extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<EduHome />} />
          <Route path="/diensten/*" element={<EduService />} />
          <Route path="/producten/*" element={<EduProduct />} />
        </Routes>
      </div>
    );
  }
}

export default Edu;
