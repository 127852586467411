import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BtnCircle from "./btns/BtnCircle";

const ProductQuickNav = (props) => {
  const location = useLocation();
  const eduProducts = useSelector((state) => state.products.edu);
  const jobProducts = useSelector((state) => state.products.job);
  const activeSector = useSelector((state) => state.activeSector);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <section
      className={`product-quicknav product-quicknav--${activeSector}`}
      id={`leerproducten-${activeSector}`}
    >
      <h2
        className="product-quicknav__title"
        dangerouslySetInnerHTML={{
          __html: `<span>Leerproducten</span> <br/> <small>voor ${
            activeSector === "edu"
              ? "educatieve leeromgevingen"
              : "de professionele leeromgeving"
          }</small>`,
        }}
      ></h2>
      <div className="product-quicknav__wrapper">
        {activeSector === "edu"
          ? eduProducts.map((eduProduct, i) => (
              <Link
                to={`/edu/producten/${eduProduct.titleInCode}`}
                key={i}
                className={`product-card product-card--edu ${
                  props.activeProduct
                    ? props.activeProduct.id === eduProduct.id
                      ? "product-card--current"
                      : ""
                    : ""
                }`}
              >
                <div className="product-card__header">
                  <img
                    alt="Symbool van het product"
                    src={eduProduct.icon}
                  ></img>
                </div>
                <div className="product-card__body">
                  <h3
                    dangerouslySetInnerHTML={{ __html: eduProduct.title }}
                  ></h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: eduProduct.cardText }}
                  ></p>
                </div>
                <div className="product-card__footer">
                  <div>
                    <BtnCircle></BtnCircle>
                    <span>Lees meer</span>
                  </div>
                </div>
              </Link>
            ))
          : jobProducts.map((jobProduct, i) => (
              <Link
                to={`/workplace/producten/${jobProduct.titleInCode}`}
                key={i}
                className={`product-card product-card--job ${
                  props.activeProduct
                    ? props.activeProduct.id === jobProduct.id
                      ? "product-card--current"
                      : ""
                    : ""
                }`}
              >
                <div className="product-card__header">
                  <img
                    alt="Symbool van het product"
                    src={jobProduct.icon}
                  ></img>
                </div>
                <div className="product-card__body">
                  <h3
                    dangerouslySetInnerHTML={{ __html: jobProduct.title }}
                  ></h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: jobProduct.cardText }}
                  ></p>
                </div>
                <div className="product-card__footer">
                  <div>
                    <BtnCircle></BtnCircle>
                    <span>Lees meer</span>
                  </div>
                </div>
              </Link>
            ))}
      </div>
    </section>
  );
};

export default ProductQuickNav;
