import React from "react";

function ProductDetails(props) {
  const product = props.activeProduct;
  return (
    <section className="product-details">
      <h2
        className="product-details__baseline"
        dangerouslySetInnerHTML={{ __html: product.baseline }}
      ></h2>
      <p
        className="product-details__baseText"
        dangerouslySetInnerHTML={{ __html: product.baseText }}
      ></p>
    </section>
  );
}

export default ProductDetails;
