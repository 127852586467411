import React, { Component } from "react";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";

class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="privacy">
        <Header onMenuToggle={this.toggleMobileMenu} headerBG="beige" />

        <section className="privacy-hero">
          <div className="privacy-hero-pagelead">
            <div className="privacy-hero-pagelead__info">
              <h1 className="privacy-hero-pagelead__info-title">
                Privacy &amp; Cookie<br></br> Policy
              </h1>
            </div>
          </div>
        </section>

        <section className="privacy-policy">
          <div className="privacy-policy__intro">
            <h3>Laatst geüpdatet: 27 april 2022</h3>
            <p>
              <strong>Oetang BVBA</strong> respecteert op haar website
              www.oetang.be de privacy van alle gebruikers. De persoonlijke
              informatie die u ons verschaft, wordt steeds in vertrouwen
              behandeld. Ons bedrijf is gevestigd te 9000 Gent en ons BTW-nummer
              is BE 0837.751.980
            </p>
          </div>

          <h3>Uw persoonsgegevens</h3>
          <h4>Verzamelen gegevens</h4>

          <p>
            Op deze website verzamelen we persoonsgegevens. Deze gegevens worden
            door ons bedrijf beheerd. Het gaat om zaken zoals:
          </p>

          <ul>
            <li>
              uw naam, e-mailadres en andere gegevens, bij het invullen van het
              contact- of inschrijfformulier op onze website
            </li>
            <li>
              alle informatie met betrekking tot de pagina’s die u op onze site
              geraadpleegd heeft
            </li>
            <li>
              alle informatie die nodig is om onze diensten voldoende te kunnen
              uitvoeren en u op de hoogte te houden van de status hiervan
            </li>
          </ul>

          <h4>Gebruik gegevens</h4>

          <p>We zetten deze gegevens in om…</p>

          <ul>
            <li>de inhoud van onze website en onze diensten te verbeteren</li>
            <li>
              u later te contacteren om op de hoogte te blijven van relevant
              nieuws en updates in onze sectoren
            </li>
            <li>
              u te contacteren in verband met (lopende) projecten en diensten
              die bij ons bezig zijn
            </li>
            <li>klanten, aanvragen en boekingen te beheren</li>
          </ul>

          <h3>Cookies</h3>
          <h4>Gebruik van cookies</h4>

          <p>
            Op deze site gebruiken we functionele cookies. Deze cookies zorgen
            ervoor dat specifieke voorkeuren bijgehouden worden op uw computer.
            Als u dan later de website bezoekt, worden deze gegevens opnieuw
            ingelezen. Hieronder valt bijvoorbeeld uw taalkeuze en welke
            pagina’s u in het verleden hebt bezocht. Deze gegevens worden door
            ons geïnterpreteerd om de kwaliteit van de website en onze diensten
            te optimaliseren.
          </p>

          <p>
            U kunt ervoor kiezen om deze cookies uit te schakelen. Dat doet u
            door gebruik te maken de mogelijkheden van uw browser. U vindt meer
            informatie over deze mogelijkheden op de website van de aanbieder
            van uw browser.
          </p>

          <h4>Cookies van derden</h4>

          <p>
            Soms wordt er verwezen naar onderdelen van externe websites. Het is
            mogelijk dat deze websites ook andere cookies bijhouden. Wij raden u
            aan de privacy policy van deze websites afzonderlijk te controleren.
          </p>

          <h3>Veiligheid</h3>
          <h4>Opslag</h4>

          <p>
            Alle gegevens die door ons bewaard worden, staan in een beveiligde
            omgeving. We doen er alles aan om de meest gangbare standaarden te
            hanteren om deze gegevens en de overdracht ervan te beveiligen.
          </p>

          <p>
            De persoonsgegevens verwerkt voor klantenbeheer zullen worden
            bewaard gedurende de termijn die noodzakelijk is om aan de
            wettelijke vereisten te voldoen (onder andere op het gebied van
            boekhouding).
          </p>

          <h4>Gegevens naar derden</h4>

          <p>
            Gegevens van scholen die nascholing boeken worden uitgewisseld met
            onze partner{" "}
            <a
              href="http://www.tenz.be"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Tenz vzw</strong>
            </a>{" "}
            (Hubert Frere-Orbanlaan 48, 9000 Gent) die instaat voor het
            verwerken van teamgerichte aanvragen en boekingen. De gegevens
            worden uitgewisseld om een goede service te kunnen verlenen en
            worden verder niet met andere partijen gedeeld.
          </p>

          <p>
            Uitgezonderd bovenstaand geval, delen wij uw gegevens nooit met
            derden. Onze werknemers en door ons ingeschakelde partners zijn
            verplicht om de vertrouwelijkheid van uw gegevens te respecteren.
          </p>

          <h4>Datalek</h4>

          <p>
            Wij doen er alles aan om uw gegevens te beveiligen, maar indien de
            privacy van uw persoonsgegevens toch in gedrang zou komen door een
            datalek, wordt u hiervan meteen op de hoogte gebracht. We doen er
            dan alles aan om dit lek in de toekomst tegen te gaan via een
            preventieve aanpak.
          </p>

          <h3>Contacteren</h3>
          <h4>Inzicht</h4>

          <p>
            U kan steeds de gegevens die wij over u bewaren opvragen. Ook als u
            wenst te reageren op ons privacy beleid, kan u ons contacteren via
            de mail of per telefoon. De gegevens zijn te vinden in de voet van
            elke webpagina.
          </p>

          <p>
            U heeft steeds het recht om “vergeten” te worden. Via onderstaande
            gegevens kan u ons contacteren hieromtrent.
          </p>

          <h4>Feedback</h4>

          <p>
            Op aanvraag bieden wij de bezoekers de mogelijkheid om eventuele
            onjuiste gegevens die wij van hen bijhouden te corrigeren. Als u uw
            persoonlijke gegevens wenst te verbeteren, gelieve ons dan te
            contacteren op de hierboven vermelde kanalen. Als u vindt dat onze
            site niet in overeenstemming is met ons privacy-beleid, neem dan
            contact op.
          </p>

          <h3>Wijzigingen</h3>

          <p>
            Wanneer wij onze privacy policy aanpassen, zullen wij de datum van
            ‘laatst geüpdatet’ aanpassen bovenaan deze policy. Bij grote
            aanpassingen kunnen we dit ook via andere kanalen doen, zoals
            e-mail.
          </p>
        </section>

        <Footer
          page="home"
          cta="Dus, wanneer <span>beginnen we?</span>"
        ></Footer>
      </div>
    );
  }
}

export default Privacy;
