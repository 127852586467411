import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { changeService } from "../redux/actions/actions";
import BtnCircle from "./btns/BtnCircle";

const ServiceOverview = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, location.pathname);
  const { eduServices, jobServices, activeSector } = props;
  return (
    <section
      className="service-overview service-overview--edu"
      id="#diensten-edu"
    >
      <h2
        className="service-overview__title"
        dangerouslySetInnerHTML={{
          __html:
            activeSector === "edu"
              ? `Wat we doen voor <span>educatieve leeromgevingen</span>`
              : `Wat we doen voor <span> workplace learning</span>`,
        }}
      ></h2>

      <div className="service-overview__wrapper">
        {activeSector === "edu"  
          ? eduServices.map((service, i) => (
              <Link
                to={`/${activeSector}/diensten/${service.titleInCode}`}
                onClick={() => dispatch(changeService(service.titleInCode))}
                key={i}
                className={`service-card service-card--${service.titleInCode}`}
              >
                <div className="service-card__header">
                  <h3 dangerouslySetInnerHTML={{ __html: service.title }}></h3>
                </div>
                <div className="service-card__body">
                  <p>{service.cardText}</p>
                </div>
                <div className="service-card__footer">
                  <BtnCircle></BtnCircle>
                  Lees meer
                  {/*over {service.title.toLowerCase()}*/}
                </div>
              </Link>
            ))
          : jobServices.map((service, i) => (
              <Link
                to={`/${activeSector}/diensten/${service.titleInCode}`}
                onClick={() => dispatch(changeService(service.titleInCode))}
                key={i}
                className={`service-card service-card--${service.titleInCode}`}
              >
                <div className="service-card__header">
                  <h3 dangerouslySetInnerHTML={{ __html: service.title }}></h3>
                </div>
                <div className="service-card__body">
                  <p>{service.cardText}</p>
                </div>
                <div className="service-card__footer">
                  <BtnCircle></BtnCircle>
                  Lees meer
                  {/*over {service.title.toLowerCase()}*/}
                </div>
              </Link>
            ))}

<Link
                to={`https://trainingen.oetang.be/`}
                className="service-card only-edu"
                target="_blank"
            >
                <div className="service-card__header"> <h3>
                    Training en trajecten
                </h3></div>
                <div className="service-card__body">
                
                <p>Voor <b>onderwijsteams</b> die zich willen versterken, biedt Oetang nascholingen aan.</p>
                </div>
                <div className="service-card__footer">
                <div>
                    <span className="btn-circle btn-circle--red"><i className="icon-external"></i></span>
                    <span>Ontdek nasscholingsaanbod</span>
                </div>
                </div>
            </Link>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  eduServices: state.services.edu,
  jobServices: state.services.job,
  activeSector: state.activeSector,
});

export default connect(mapStateToProps, null)(ServiceOverview);
