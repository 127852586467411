import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { serviceHeroImgs } from "../assets/imgs/services/ServiceImgImports";
import HeroImgJob from "../assets/imgs/algemene_beelden/oetang_job-hero.png";
import HeroImgEdu from "../assets/imgs/algemene_beelden/oetang_edu-hero.png";

class SectorHomeHero extends Component {
  render() {
    const { activeSector, activeProduct } = this.props;
    return (
      <section className={`${activeSector}home-hero`}>
        <div className={`${activeSector}home-hero-pagelead`}>
          <div className={`${activeSector}home-hero-pagelead__info`}>
            <h1
              className={`${activeSector}home-hero-pagelead__info-title`}
              dangerouslySetInnerHTML={{
                __html: `${
                  activeSector === "edu" ? "Education" : "Workplace learning"
                }`,
              }}
            ></h1>
            <p
              className={`${activeSector}home-hero-pagelead__info-lead`}
              dangerouslySetInnerHTML={{
                __html:
                  activeSector === "edu"
                    ? "Oetang werkt in en voor educatieve contexten: het onderwijs, het brede cultuur- en erfgoedveld, de 'grote educaties' zoals gezondheid, milieu, duurzaamheid ..."
                    : "Oetang is graag partner van Learning & Development diensten die  mikken op <em>lifelong learning</em> en <em>workplace learning</em> bij hun medewerkers.",
              }}
            ></p>
            <div className={`${activeSector}home-hero-pagelead__info-btns`}>
              <HashLink
                smooth
                className={`${activeSector}home-hero__btn btn-green-on-green`}
                to="#diensten-edu"
                elementId="#diensten-edu"
              >
                Diensten<i className="icon-arrow-down"></i>
              </HashLink>
              <HashLink
                smooth
                to={`#leerproducten-${activeSector}`}
                className={`${activeSector}home-hero__btn btn-green-on-green`}
              >
                Producten<i className="icon-arrow-down"></i>
              </HashLink>
            </div>
          </div>
          <div className={`${activeSector}home-hero-pagelead__aside`}>
            <img
              alt=""
              src={activeSector === "edu" ? HeroImgEdu : HeroImgJob}
            ></img>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSector: state.activeSector,
});

export default connect(mapStateToProps)(SectorHomeHero);
