import { connect } from "react-redux";
import React from "react";
import Footer from "../../../components/Footer";
import Motto from "../../../components/Motto";
import ServiceQuickNav from "../../../components/ServiceQuickNav";
import ServiceProces from "../../../components/ServiceProces";
import ServiceHero from "../../../components/ServiceHero";
import LinkedCases from "../../../components/LinkedCases";
import Helmet from "react-helmet";
import { shuffleCasesWithService } from "../../../redux/actions/actions";

function EduOnderzoek(props) {
  const { services, cases } = props;
  // const [isMenuOpen, setMenu] = useState(false);

  // const toggleMenu = () => {
  //   if (!isMenuOpen) setMenu(true);
  //   else setMenu(false);
  // };

  // const shuffleCasesWithService = (idOfService) => {
  //   const checkServicegroup = (casus) => {
  //     return casus.servicegroup.includes(idOfService);
  //   };
  //   const casesWithService = props.cases.filter(checkServicegroup);
  //   const shuffledCasesWithService = casesWithService.sort(
  //     () => 0.5 - Math.random()
  //   );
  //   const selectedCasesWithService = shuffledCasesWithService.slice(0, 3);
  //   const linkedCaseTitles = selectedCasesWithService.map((selectedCase) => {
  //     return selectedCase.titleInCode;
  //   });
  //   return linkedCaseTitles;
  // };
  return (
    <div>
      <Helmet>
        <title>
          {services[0].title
            .replace(/(<([^>]+)>)/gi, "")
            .replace("&shy;", "")
            .replace("&#8209;", "-")}
        </title>
        <meta
          name="description"
          content={services[0].lead
            .replace(/(<([^>]+)>)/gi, "")
            .replace("&shy;", "")}
        ></meta>
        <meta name="keywords" content={services[0].keywords}></meta>
      </Helmet>
      <ServiceHero activeService={services[0]}></ServiceHero>
      <Motto source={services[0].motto}></Motto>
      <ServiceProces activeService={services[0]}></ServiceProces>
      <ServiceQuickNav activeService={services[0].title}></ServiceQuickNav>
      <LinkedCases
        title="Een greep uit <br/>onze <span>realisaties</span>"
        caseTitles={shuffleCasesWithService(1).payload.linkedCaseTitles}
      ></LinkedCases>
      <Footer cta={services[0].footerCta}></Footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: state.services.edu,
  cases: state.cases,
});

export default connect(mapStateToProps, null)(EduOnderzoek);
